import React, { useContext, useEffect, useRef, useState } from 'react';
import HtmlParser from 'react-html-parser';
import styled from 'styled-components';
import CustomScroll from 'react-custom-scroll';

import { ToastContext } from 'modules/context/ToastContext';
import { palette, zIndex } from 'constants/styles';
import Success from 'assets/icons/Success';
import Exit from 'assets/icons/Exit';
import Warning from 'assets/icons/Warning';
import Error from 'assets/icons/Error';
import RoundButton from 'components/button/RoundButton';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const ToastComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  position: fixed;
  right: -400px;
  top: 80px;
  z-index: ${zIndex.toastPopup};
  max-height: ${props => props.maxH}px;
  transition: transform 0.5s;

  &.display {
    transform: translateX(-400px);
  }
`;

const ToastWraaper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const ToastClearWarpper = styled.div`
  margin: 0 20px;
  ${props => (props.theme === 'viewer' && `
    button {
      background-color: ${palette.grey['5']};
    }
  `)}
`;

const ToastExit = styled.button`
  width: 25px;
  height: 25px;
  position: absolute;
  top: -10px;
  left: -10px;
  border-radius: 50%;
  background-color: ${props => (props.theme ? palette.grey[5] : palette.navy[3])};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToastBox = styled.div`
  position: relative;
  width: 400px;
  min-height: 85px;
  padding: 20px 25px;
  border-radius: 15px;
  background-color: ${props => (props.theme === 'viewer' ? palette.semantic.gray['2'] : palette.navy[0])};
  margin-top: 20px;
  margin-left: 10px;
  right: -400px;

  &:first-child {
    margin-top: 0;
  }

  ${ToastExit} {
    visibility: hidden;
  }

  &:hover ${ToastExit} {
    visibility: visible;
  }
`;

const ToastTitle = styled.div`
  font-size: 15px;
  color: ${palette.primary.white};
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  svg {
    margin-right: 10px;
  }
`;

const ToastText = styled.div`
  margin-left: 26px;
  white-space: pre-line;
`;

const Toast = () => {
  const [count, setCount] = useState(0);
  const [hiddenToast, setHiddenToast] = useState();
  const [maxHeight, setMaxHeight] = useState();
  const toast = useContext(ToastContext);
  const toastbox = 'toast';
  const $toastElement = useRef();
  const location = useLocation();

  useEffect(() => {
    (async () => { 
      try {
        window.addEventListener('load', handleMaxHeight);
        window.addEventListener('resize', handleMaxHeight);
        return () => {
          window.removeEventListener('resize', handleMaxHeight);
          window.removeEventListener('load', handleMaxHeight);
        };
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    try {
      for (let i = 0; i < count; i += 1) {
        if (document.getElementById(toastbox + i) && document.getElementById(toastbox + i).style.transform === '') {
          document.getElementById(toastbox + i).style.transform = 'translateX(-400px)';
        }
      }
      if (count < toast.toastState.length) {
        if (document.getElementById(toastbox + count).style.transform === '') {
          document.getElementById(toastbox + count).style.transform = 'translateX(-400px)';
          document.getElementById(toastbox + count).style.transition = 'transform 0.5s';
        }
      }
      setCount(toast.toastState.length);
      handleClearTimeOut();
      handleSetTimeOut();
    } catch (error) {
      console.log(error);
    }
  }, [toast.toastState]);

  useEffect(() => {
    try {
      if (toast.toastDisplay) {
        $toastElement.current.classList.add('display');
      } else {
        $toastElement.current.classList.remove('display');
      }
    } catch (error) {
      console.log(error);
    }
  }, [toast.toastDisplay]);

  const handleMaxHeight = () => {
    setMaxHeight(window.innerHeight - 90);
  };

  const handleDelete = (index) => {
    console.log(index);
    document.getElementById(toastbox + index).style.transform = 'translateX(430px)';
    document.getElementById(toastbox + index).style.transition = 'transform 0.5s';

    if (index + 1 < count) {
      for (let i = index + 1; i < count; i += 1) {
        document.getElementById(toastbox + i).style.transform = `translate(-400px, -${document.getElementById(toastbox + index).clientHeight + 20}px)`;
        document.getElementById(toastbox + i).style.transition = 'transform 0.5s';
      }
    }
    setTimeout(() => {
      document.getElementById(toastbox + index).style.transform = 'translateX(-400px)';
      document.getElementById(toastbox + index).style.transition = null;
      for (let i = index + 1; i < count; i += 1) {
        document.getElementById(toastbox + i).style.transform = 'translateX(-400px)';
        document.getElementById(toastbox + i).style.transition = null;
      }
      toast.delete(index);
    }, 500);
  };

  const handleClearTimeOut = () => {
    clearTimeout(hiddenToast);
  };

  const handleSetTimeOut = () => {
    if (location.pathname !== '/') {
      setHiddenToast(setTimeout(() => {
        toast.display(false);
      }, 3000));
    }
  };

  const handleClearToast = () => {
    toast.clear();
    toast.display(false);
  };
  
  return (
    <ToastComponent ref={$toastElement} onMouseOver={handleClearTimeOut} onMouseOut={handleSetTimeOut} maxH={maxHeight}>
      <ToastWraaper>
        <ToastClearWarpper theme={toast.toastTheme}>
          <RoundButton theme="small" onClick={handleClearToast}>Clear All</RoundButton>
        </ToastClearWarpper>
        <CustomScroll heightRelativeToParent={`${Math.min(maxHeight, 150 * Object.entries(toast.toastState).length)}px`}>
          <div style={{ paddingTop: '10px' }}>
            {Object.entries(toast.toastState).map((value, index) => (
              <ToastBox key={index} id={toastbox + index} theme={toast.toastTheme}>
                <ToastTitle>
                  {value[1].kind === 'success' && <Success />}
                  {value[1].kind === 'warning' && <Warning />}
                  {value[1].kind === 'error' && <Error />}
                  {value[1].title}
                </ToastTitle>
                <ToastText>{HtmlParser(value[1].text)}</ToastText>
                <ToastExit theme={value[1].theme} onClick={() => handleDelete(index)}><Exit /></ToastExit>
              </ToastBox>
            ))}
          </div>
        </CustomScroll>
      </ToastWraaper>
    </ToastComponent>
  );
};

export default Toast;