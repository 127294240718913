import qs from 'qs';
import { apiPath } from 'constants/paths';
import { errorCheck } from 'utils/functions';
import { httpAPI } from './http';

const ViewerAPI = (() => {
  function ViewerAPIConstructor() {}

  ViewerAPIConstructor.prototype = {
    cephLandmark(data, cookies) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.cephLandmark, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: qs.stringify(data),
          cookies
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    cephAutoalign(data, cookies) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.cephAutoalign, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${cookies[0].token}`
          },
          data: JSON.stringify(data),
          cookies
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            console.log(error);
            reject(error);
          });
      });
    },

    updateShootDate(data, cookies) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.updateShootDate, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${cookies[0].i.token}`
          },
          data: JSON.stringify(data),
          cookies
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    fileUpload(data, file) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.fileUpload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: qs.stringify(data)
        })
          .then((response) => {
            httpAPI('', response.presignedUrl, {
              method: 'PUT',
              headers: {
                'Content-Type': 'image',
              },
              data: file
            })
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                errorCheck(error);
                reject(error);
              });
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    fileDownload(data, cookies) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.fileDownload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${cookies[0].i.token}`
          },
          data: qs.stringify(data)
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    chartDataRequest(data, cookies) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.chartDataRequest, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${cookies[0].token}`
          },
          data: qs.stringify(data)
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error); 
          });
      });
    },

    cephSuperimposition(data, cookies) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.cephSuperimposition, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${cookies[0].token}`
          },
          data: JSON.stringify(data),
          cookies
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },
  
    licenseUpload(data, file) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.licenseUpload, {
          data: qs.stringify(data),
        })
          .then((response) => {
            httpAPI('', response.presignedUrl, {
              method: 'PUT',
              headers: {
                'Content-Type': 'image',
              },
              data: file
            })
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                errorCheck(error);
                reject(error);
              });
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    geoRedirector(data) {
      return new Promise((resolve, reject) => {
        // httpAPI('', 'http://localhost:8888/geolocation', {
        httpAPI('', 'https://georedir.ddhinc.net/geolocation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(data),
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            console.log(error);
            reject(error);
          });
      });
    }
  };
  return ViewerAPIConstructor;
})();

export const viewerAPI = new ViewerAPI();
