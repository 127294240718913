import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Cookies } from 'react-cookie';
import { GlobalContext } from 'modules/context/GlobalContext';
import { ViewerContext } from 'modules/context/ViewerContext';

import ChangeComponent, {
  ExitButton,
  ModalContentWrapper
} from 'components/board/ChangeComponent';
import IconTimes from 'assets/icons/Times';
import { ImageItem, UploadButton } from 'components/board/CreateComponent';
import DefaultButton from 'components/button/DefaultButton';
import { ToastContext } from 'modules/context/ToastContext';
import { CREATE_CHART_MUTATION, DELETE_DDHAIM_CHART } from 'modules/queries/chart';
import { CREATE_CP_CEPPRO, UPDATE_CP_CEPPRO, GET_ALL_CP_CEPPROS } from 'modules/queries/viewer';
import Localization from 'i18n';
import { pagePaths } from 'constants/paths';
import {
  getToday,
  handleDatePicker,
  cepUploadProcess,
  convertingImages,
  cephLandmarkFunc
} from 'utils/functions';
import FormItem from 'components/forms/FormItem';
import { PopupContext } from 'modules/context/PopupContext';
import { Loading } from 'components/common/Loading';
import { useDeleteChart } from 'modules/queries/commonQueries';

const WebSocketHost = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_WEBSOCKET_PROD
  : process.env.REACT_APP_WEBSOCKET_DEV;

const ChangeContainer = ({ history }) => {
  const downCookies = new Cookies();
  const [shootDate, setShootDate] = useState(getToday());
  const globalContext = useContext(GlobalContext);
  const viewerState = useContext(ViewerContext);
  const toastState = useContext(ToastContext);
  const [imgData, setImgData] = useState({
    cepparo: null,
    face: null
  });
  const [newChartId, setNewChartId] = useState(null);
  const popupState = useContext(PopupContext);
  const [cepFile, setCepFile] = useState();
  const [faceFile, setFaceFile] = useState();
  const [flag, setFlag] = useState(false);
  const [kind, setKind] = useState();

  const [deleteChart] = useMutation(DELETE_DDHAIM_CHART);
  const [createCpCeppro] = useMutation(CREATE_CP_CEPPRO);
  const [updateCpCeppro] = useMutation(UPDATE_CP_CEPPRO);
  const [getCpCepprosId] = useLazyQuery(GET_ALL_CP_CEPPROS);
  const [createChart] = useMutation(CREATE_CHART_MUTATION);

  const handleSave = () => {
    // chartid 확인
    const checkid = globalContext.chartId;
    const chartUploadType = checkid === undefined || checkid === '' ? 'create' : 'change';
    globalContext.update({ isLoading: true });
    const converting = convertingImages(imgData);

    Promise.all(converting).then(([cepImg, faceImg]) => {
      setCepFile(() => cepImg);
      if (faceImg !== undefined) {
        setFaceFile(() => faceImg);
      }

      if (chartUploadType === 'create') {
        createChart({
          variables: {
            data: {
              ddhaim_patient: globalContext.viewPatientID
            }
          },
          onCompleted: (response) => {
            setNewChartId(response.createDdhaimChart.data.id);
            setKind('create');
            setFlag(true);
          },
          onError: (error) => {
            globalContext.update({ isLoading: false });
            popupState.update({
              display: true,
              title: Localization.xray_not_recognized,
              text: Localization.check_xray,
              textConfirm: Localization.confirm
            });
            console.log(error);
          }
        });
      } else if (chartUploadType === 'change') {         
        setKind('change');
        setFlag(true);
      }
    });
  };

  const handleImgRemove = (type, e) => {
    e.preventDefault();
    e.stopPropagation();
    setImgData((prev) => ({ ...prev, [type]: null }));
  };

  const handleCloseModal = () => {
    // 'view/change'는 modalMode가 view인 상태에서 chnage로 갈경우 view/change로 설정
    // 2개의 모달이 띄어져있기 때문에 displayModal로 modal를 꺼주는 것이 아니라 제일 상단의 change만 삭제하고 view modal은 띄워줘야함
    if (globalContext.modalMode === 'view/change') {
      globalContext.update({ modalMode: 'view' });
    } else {
      globalContext.update({ displayModal: false });
    }
  };
  const handleChangeFile = (name, file) => {
    const value = URL.createObjectURL(file);
    setImgData((prev) => ({ ...prev, [name]: value }));
  };

  const xrayErrorPopup = () => {
    return popupState.update({
      display: true,
      title: Localization.xray_not_recognized,
      text: Localization.check_xray,
      textConfirm: Localization.confirm
    });
  };

  useEffect(() => {
    try {
      if (flag) {
        let socketInstance = null;
        const accessToken = downCookies.get('token');
        const message = {
          'type':'jwt',
          'token':accessToken
        };
        const cnt = faceFile !== undefined ? 2 : 1;
        let cntCheck = 0;
        socketInstance = new WebSocket(`${WebSocketHost}`);
        socketInstance.onopen = () => {
          socketInstance.send(JSON.stringify(message));
        };

        // change에서 새로 차트 만드는 경우
        if (newChartId) {
          if (kind === 'create') {
            cepUploadProcess(cepFile, faceFile, newChartId, shootDate)
              .then(() => {
              }).catch((error) => {
                console.log(error);
                globalContext.update({ isLoading: false });
                xrayErrorPopup();
                useDeleteChart(deleteChart, newChartId);
              });
          }
        } else if (kind === 'change') {
          cepUploadProcess(cepFile, faceFile, globalContext.chartId, shootDate)
            .then(() => {
            }).catch((error) => {
              console.log(error);
              globalContext.update({ isLoading: false });
              xrayErrorPopup();
            });
        }
        socketInstance.onmessage = (msg) => {
          if (msg.data === 'DB thumbnail Saved') {
            cntCheck++;
            if (cnt === cntCheck) {
              if (kind === 'create') {
                cephLandmarkFunc(newChartId).then(([temp, response]) => {
                  if (temp.length > 0) {
                    createCpCeppro({
                      variables: {
                        data: {
                          ddhaim_chart: newChartId,
                          infer_result: { ...temp },
                          modified_result: { ...temp },
                          profilogram_result: viewerState.profilogramStandard,
                          vto_sto_config: {
                            data: { ...temp },
                            autoAlign: {},
                            point: [],
                            affectSvgTransform: {}
                          },
                          drawing_result: { coords: null, superimpositionCoords: null },
                          img_size: response.output.size
                        }
                      },
                      onCompleted: () => {
                        socketInstance.close();
                        cntCheck = 0;

                        toastState.display(true);
                        toastState.theme('basic');
                        toastState.update({
                          kind: 'success',
                          title: Localization.success,
                          text: Localization.success_save
                        });
                  
                        // set loading
                        globalContext.update({ isLoading: false });
                        handleCloseModal();
                      },
                      onError: (error) => {
                        console.log(error);
                        globalContext.update({ isLoading: false });
                      }
                    });                 
                  }
                }).catch((error) => {
                  console.log(error);
                  globalContext.update({ isLoading: false });
                  socketInstance.close();
                  cntCheck = 0;
                  xrayErrorPopup();
                  useDeleteChart(deleteChart, newChartId);
                });
              } else if (kind === 'change') {
                cephLandmarkFunc(globalContext.chartId).then(([temp, response]) => {
                  getCpCepprosId({
                    variables: {
                      filters: {
                        ddhaim_chart: {
                          id: {
                            eq: globalContext.chartId
                          }
                        }
                      }
                    },
                    onCompleted: (data) => {
                      updateCpCeppro({
                        variables: {
                          updateCpCepproId: data.cpCeppros.data[0].id,
                          data: {
                            infer_result: { ...temp },
                            modified_result: { ...temp },
                            profilogram_result: viewerState.profilogramStandard,
                            vto_sto_config: {
                              data: { ...temp },
                              autoAlign: {},
                              point: [],
                              affectSvgTransform: {}
                            },
                            img_size: response.output.size
                          }
                        },
                        onCompleted: () => {
                          globalContext.update({ isLoading: false });
                          socketInstance.close();
                          cntCheck = 0;
                          toastState.display(true);
                          toastState.theme('basic');
                          toastState.update({
                            kind: 'success',
                            title: Localization.success,
                            text: Localization.success_save
                          });
                          handleCloseModal();
                        },
                        onError: (error) => {
                          globalContext.update({ isLoading: false });
                          console.log(error);
                        }
                      });
                    }
                  }).catch((error) => {
                    console.log(error);
                    globalContext.update({ isLoading: false });
                    socketInstance.close();
                    cntCheck = 0;
                    useDeleteChart(deleteChart, newChartId);
                    xrayErrorPopup();
                  });
                }).catch((error) => {
                  console.log(error);
                  globalContext.update({ isLoading: false });
                  socketInstance.close();
                  cntCheck = 0;
                  useDeleteChart(deleteChart, newChartId);
                  history.push(pagePaths.list);
                  xrayErrorPopup(); 
                });
              }
            }
          }
        };
      }
    } catch (error) {
      console.log(error);
      globalContext.update({ isLoading: false });
      xrayErrorPopup();
    }
  }, [flag]);

  return (
    <>
      {globalContext.isLoading && <Loading />}
      <ChangeComponent title={Localization.upload_new_image}>
        <ExitButton type="button" onClick={handleCloseModal}>
          <IconTimes />
        </ExitButton>

        <ModalContentWrapper>
          <ImageItem
            title={Localization.cephalometric}
            icon="cepparo"
            name="cepparo"
            src={imgData.cepparo}
            onClick={(e) => handleImgRemove('cepparo', e)}
            onChange={handleChangeFile}
          >
            Drop your image here, or <strong>browse</strong>
            <span>File format: jpg / png</span>
          </ImageItem>
          <ImageItem
            title={Localization.left_face_image}
            icon="sideface"
            name="face"
            src={imgData.face}
            onClick={(e) => handleImgRemove('face', e)}
            onChange={handleChangeFile}
          >
            Drop your image here, or <strong>browse</strong>
            <span>File format: jpg / png</span>
          </ImageItem>
        </ModalContentWrapper>
        <ModalContentWrapper>
          <FormItem
            label={Localization.patient_img_date}
            type="date"
            name="changeShoot"
            maxLength="30"
            value={new Date(shootDate)}
            disabled={globalContext.modalMode === 'add'}
            onDateChange={(date) => {
              handleDatePicker('changeShoot', date, '', setShootDate);
            }}
          />
        </ModalContentWrapper>

        <UploadButton>
          <DefaultButton disabled={!imgData.cepparo} onClick={handleSave}>
            {Localization.save}
          </DefaultButton>
        </UploadButton>
      </ChangeComponent>
    </>
  );
};

export default withRouter(ChangeContainer);
