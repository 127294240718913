import React from 'react';
import HtmlParser from 'react-html-parser';
import styled from 'styled-components';

import { palette, zIndex } from 'constants/styles';

const CookieBannerWrapper = styled.div`
  width: 1030px;
  padding: 20px 200px 20px 30px;
  position: absolute;
  z-index: ${zIndex.cookieBanner};
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 123px;
  background-color: ${palette.navy['5']};
  border-radius: 10px;
`;

const CookieBannerTitle = styled.h4`
  font-weight: bold;
  font-size: 20px;
  color: ${palette.primary.white};
  margin-bottom: 15px;
`;

const CookieBannerText = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${palette.grey['4']};
  a {
    color: ${palette.primary.blue};
    text-decoration: underline;
  }
`;
const CookieBannerButton = styled.div`
  position: absolute;
  top: 64px;
  right: 30px;
`;

const CookieBanner = ({ title, text, children }) => (
  <CookieBannerWrapper>
    <CookieBannerTitle>{title}</CookieBannerTitle>
    <CookieBannerText>{HtmlParser(text)}</CookieBannerText>
    <CookieBannerButton>
      {children}
    </CookieBannerButton>
  </CookieBannerWrapper>
);

export default CookieBanner;