import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { fontEN, palette, size, zIndex } from 'constants/styles';
import IconSettings from 'assets/icons/Settings';
import IconLogout from 'assets/icons/Logout';
import IconLocal from 'assets/icons/Local';
import IconChart from 'assets/icons/Chart';
import RadioButton from 'components/forms/RadioButton';
import CepproLogo from 'assets/logo-ceppro.svg';
import HeaderUser from 'assets/icons/HeaderUser';
import BackButton from 'components/button/BackButton';
import Localization from 'i18n';
import { GlobalContext } from 'modules/context/GlobalContext';
import { manualLink } from 'constants/links';
import Download from 'assets/icons/Download';

const HeaderFormContainer = styled.header`
  position: relative;
  min-width: ${size.minWidth}px;
  width: 100%;
  height: ${size.header}px;
  display: flex;
`;

const LogoWrapper = styled.img`
  height: 20px;
  // beta logo height: 30px
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translate(0, -50%);
`;

const LocalWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  position: absolute;
  right: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChartButtonWrapper = styled.button`
  background-color: ${palette.navy['0']};
  border-radius: 17px;
  font-weight: 500;
  font-size: 14px;
  color: ${palette.primary.white};
  padding: 7px 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  :hover:not(:disabled) {
    background-color: ${palette.background.navy};
  }
  :disabled {
    background-color: ${palette.grey['5']};
    color: ${palette.grey['4']};
    svg path {
      fill: ${palette.grey['4']};
    }
  }
  svg {
    margin-right: 5px;
  }
`;
export const ChartButton = (props) => (
  <ChartButtonWrapper type="button" {...props}>
    <IconChart />
    {props.children}
  </ChartButtonWrapper>
);

const HeaderLoginWrapper = styled.div`
  display: flex;
  position: relative;
  height: 20px;
  a {
    margin-right: 15px;
  }

  button + button {
    margin-left: 15px;
  }
  button.active {
    position: relative;
    :before {
      content: '';
      position: absolute;
      right: 3px;
      top: 2px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${palette.semantic.lightRed};
    }
  }

  &:before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 10px;
    margin: auto 15px;
    background-color: rgba(241, 241, 241, 0.5);
  }
`;

const HeaderUserWrapper = styled.button`
  position: relative;
  width: 175px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${palette.semantic.gray['1']};
  border-radius: 14px;
  margin-left: 15px;
  color: ${palette.primary.white};
  &:hover {
    background-color: ${palette.semantic.gray['0']};
  }
  ${(props) => props.active
    && `
    background-color: ${palette.semantic.gray['0']};
  `}
`;

const HeaderUserEmailWrapper = styled.div`
  width: 125px;
  font-family: ${fontEN};
  font-size: 12px;
  text-align: center;
  margin: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HeaderDropBoxWrapper = styled.div`
  width: 175px;
  height: 89px;
  padding: 10px 12px;
  background-color: ${(props) => (props.theme === 'viewer' ? palette.semantic.gray['2'] : palette.navy['0'])};
  border-radius: 12px;
  position: absolute;
  top: 33px;
  right: 0;
  z-index: ${zIndex.floating};
  display: flex;
  flex-direction: column;
  align-items: start;
  font-weight: 700;
  font-size: 13px;
  color: ${palette.grey['1']};
  z-index: ${zIndex.cookieBanner};
`;

const HeaderDropBoxNameWrapper = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    margin: 10px 0;
    background-color: rgba(241, 241, 241, 0.15);
  }
`;

const HeaderDropBoxItemsWrapper = styled.button`
  width: 100%;
  height: 28px;
  border-radius: 5px;
  text-align: start;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  font-weight: inherit;
  svg {
    margin-right: 5px;
  }
  &:hover {
    background-color: ${palette.semantic.gray['0']};
  }
`;

let hoverUserLayer = false;
export const HeaderLogin = ({ email, name, theme, onSetting, onLogout }) => {
  const [active, setActive] = useState(false);
  const globalState = useContext(GlobalContext);

  const manualList = {
    link: globalState.langIndex === 0 ? manualLink.ko : manualLink.en
  };

  const handleClose = () => {
    if (hoverUserLayer) return;
    setActive(false);
  };
  useEffect(() => {
    (async () => {
      try {
        document.addEventListener('click', handleClose, true);
        return () => {
          document.removeEventListener('click', handleClose, true);
        };
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <HeaderLoginWrapper>
      {/* 알람 icon */}
      {/* <button type="button" className="active">
        <IconBell />
      </button> */}
      <a
        href={manualList.link}
        download
        rel="noopener noreferrer"
      >
        <Download />
      </a>
      <button type="button" onClick={onSetting}>
        <IconSettings />
      </button>
      <HeaderUserWrapper
        active={active}
        onClick={() => setActive((prev) => !prev)}
        onMouseEnter={() => (hoverUserLayer = true)}
        onMouseLeave={() => (hoverUserLayer = false)}
      >
        <HeaderUser />
        <HeaderUserEmailWrapper>{email}</HeaderUserEmailWrapper>
        <Arrowdown color={palette.semantic.gray['1']} />
      </HeaderUserWrapper>
      {active && (
        <HeaderDropBoxWrapper
          theme={theme}
          onMouseEnter={() => (hoverUserLayer = true)}
          onMouseLeave={() => (hoverUserLayer = false)}
        >
          <HeaderDropBoxNameWrapper>{name}</HeaderDropBoxNameWrapper>
          <HeaderDropBoxItemsWrapper type="button" onClick={onLogout}>
            <IconLogout /> {Localization.sign_out}
          </HeaderDropBoxItemsWrapper>
        </HeaderDropBoxWrapper>
      )}
      {/* <button type="button" onClick={onLogout}>
        <IconLogout />
      </button> */}
    </HeaderLoginWrapper>
  );
};

const SelectLangGroupWrapper = styled.div`
  position: relative;
  display: flex;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
export const Arrowdown = styled.div`
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid
    ${(props) => (props.color ? props.color : palette.primary.white)};
`;

const LangsSelectLayerWrapper = styled.div`
  z-index: ${zIndex.cookieBanner};
  position: absolute;
  top: 33px;
  right: 0;
  width: 175px;
  /* background: ${(props) => (props.theme === 'black' ? '#242423' : palette.navy['0'])}; */
  background: ${(props) => (props.theme === 'viewer' ? palette.semantic.gray['2'] : palette.navy['0'])};
  border-radius: 5px;
  padding: 5px;
`;
const HeaderLabel = styled.span`
  min-width: 16px;
  height: 20px;
  color: ${palette.primary.white};
  font-size: 12px;
  vertical-align: top;
  margin: 0 5px;
  text-transform: uppercase;
`;

let hoverLangLayer = false;
export const SelectLangGroup = ({ langs, selectedIndex, onClick, theme }) => {
  const [display, setDisplay] = useState(false);

  const handleClose = () => {
    if (hoverLangLayer) return;
    setDisplay(false);
  };
  const handleSelect = (i) => {
    if (onClick) onClick(i);
    setDisplay(false);
  };

  useEffect(() => {
    (async () => {
      try {
        document.body.addEventListener('click', handleClose, false);
        return () => {
          document.body.removeEventListener('click', handleClose);
        };
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <>
      <SelectLangGroupWrapper
        onMouseEnter={() => (hoverLangLayer = true)}
        onMouseLeave={() => (hoverLangLayer = false)}
        onClick={() => setDisplay(!display)}
      >
        <IconLocal />
        <HeaderLabel>{Object.keys(langs)[selectedIndex]}</HeaderLabel>
        <Arrowdown />
        {display && (
          <LangsSelectLayerWrapper
            onMouseEnter={() => (hoverLangLayer = true)}
            onMouseLeave={() => (hoverLangLayer = false)}
            theme={theme}
          >
            {Object.entries(langs).map((lang, index) => (
              <RadioButton
                theme="box"
                hover={theme === 'black' && 'black'}
                key={index}
                checked={selectedIndex === index}
                onClick={() => handleSelect(index)}
              >
                {lang[1]}
              </RadioButton>
            ))}
          </LangsSelectLayerWrapper>
        )}
      </SelectLangGroupWrapper>
    </>
  );
};

const BackButtonWrapper = styled.div`
  margin-left: 255px;
  display: flex;
  height: 100%;
  align-items: center;
`;

const Header = (props) => (
  <HeaderFormContainer
    id="header"
  >
    <Link to={props.logoLink}>
      <LogoWrapper src={CepproLogo} alt="ddhaim ceppro" />
    </Link>
    {(props.preLocation === 'viewer'
      || props.preLocation === 'setting'
      || props.preLocation === 'myinfo') && (
      <BackButtonWrapper>
        <BackButton
          theme={
            props.preLocation === 'viewer'
              ? 'list'
              : (props.preLocation === 'setting'
                  || props.preLocation === 'myinfo')
                && 'back'
          }
          onClick={props.onClick}
        />
      </BackButtonWrapper>
    )}
    <LocalWrapper>{props.children}</LocalWrapper>
  </HeaderFormContainer>
);

export default Header;
