import React from 'react';
import styled from 'styled-components';
import DefaultButton from 'components/button/DefaultButton';
import { palette } from 'constants/styles';
import SearchForm from './SearchForm';

const BoardTitle = styled.h4`
  margin-right: 35px;
  padding-left: 15px;
  color: ${palette.primary.white};
  font-size: 18px;
  font-weight: 700;
`;

const BoardHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 70px;
  padding: 15px;
  border-radius: 8px 8px 0 0;
  background-color: ${palette.navy[2]};
  & > div:first-child {
    display: flex;
    align-items: center;
  }

  .btn-back {
    position: absolute;
    top: 66px;
    left: 30px;
  }
`;

const BoardHeaderButtons = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const AddButton = styled(DefaultButton)`
  min-width: unset;
  margin-left: 13px;
  svg {
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 5px;
  }
`;
export const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  margin-left: 10px;
  svg {
    vertical-align: middle;
  }
`;

// theme = list | create | edit
export const BoardHeader = ({
  title,
  theme,
  children,
  onKeyPress,
  onChange
}) => (
  <BoardHeaderWrapper theme={theme}>
    <div>
      <BoardTitle>{title}</BoardTitle>
      {onKeyPress && (
        <SearchForm
          onKeyPress={onKeyPress}
          theme="basic"
          onChange={onChange}
        />
      )}
    </div>
    <BoardHeaderButtons>
      {children}
    </BoardHeaderButtons>
  </BoardHeaderWrapper>
);
