import React from 'react';

const Logout = ({ width, height, color }) => (
  <svg width={width || '18'} height={height || '18'} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color || '#F1F1F1'} d="M12 12.75L15.75 9L12 5.25" strokeLinecap="round" strokeLinejoin="round" />
    <path stroke={color || '#F1F1F1'} d="M15.75 9H6.75" strokeLinecap="round" strokeLinejoin="round" />
    <path stroke={color || '#F1F1F1'} d="M6.75 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V3.75C2.25 3.35218 2.40804 2.97064 2.68934 2.68934C2.97064 2.40804 3.35218 2.25 3.75 2.25H6.75" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Logout;