import React from 'react';
import styled from 'styled-components';

import { palette, size, zIndex } from 'constants/styles';

const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${size.minWidth}px;
  min-height: ${size.minHeight}px;
  z-index: ${zIndex.floating};
`;

const ModalInner = styled.div`
  position: relative;
  width: ${size.modalWidth}px;
  background-color: ${palette.navy['1']};
  padding: 50px 50px 45px;
  border-radius: 8px;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-weight: bold;
  font-size: 18px;
  color: ${palette.primary.white};
  margin-bottom: 25px;
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
`;

export const ExitButton = styled.button`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const ChangeComponent = ({ children, title }) => {
  return (
    <ModalWrapper>
      <ModalInner>
        <ModalHeader>{title}</ModalHeader>
        {children}
      </ModalInner>
    </ModalWrapper>
  );
};

export default ChangeComponent;