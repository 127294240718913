import React from 'react';

const Exit = ({ width, height, color }) => (
  <svg width={width || '9'} height={height || '9'} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 1L1 8" stroke={color || '#C3C6D1'} strokeWidth="1.39294" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 1L8 8" stroke={color || '#C3C6D1'} strokeWidth="1.39294" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default Exit;