import { useContext } from 'react';
import { GlobalContext } from 'modules/context/GlobalContext';
import { useQuery } from '@apollo/client';
import { GET_ALL_CP_USER_CONFIGS } from './user';

export const useGetAllCpUserConfigs = () => {
  const globalState = useContext(GlobalContext);
    
  return useQuery(GET_ALL_CP_USER_CONFIGS, {
    variables: {
      filters: {
        ddhaim_user: {
          id: {
            eq: globalState.user.user_info.id
          }
        }
      }
    },
    onCompleted: () => {
    },
    onError: (error) => {
      console.log(error);
    }
  });
};

export const useDeletePatient = (deletePatient, newPatientId) => {
  return deletePatient({
    variables: {
      deleteDdhaimPatientId: newPatientId
    },
    onCompleted: () => {
    },
    onError: (error) => {
      console.log(error);
    }
  });
};

export const useDeleteChart = (deleteChart, newChartId) => {
  return deleteChart({
    variables: {
      deleteDdhaimChartId: newChartId
    },
    onCompleted: () => {
    },
    onError: (error) => {
      console.log(error);
    }
  });
};
