import React, { useContext } from 'react';
import styled from 'styled-components';
import { fontEN, fontKR, palette } from 'constants/styles';
import IconSort from 'assets/icons/Sort';
import Edit from 'assets/icons/Edit';
import { GlobalContext } from 'modules/context/GlobalContext';
import DeleteIcon from 'assets/icons/Times';
import { PopupContext } from 'modules/context/PopupContext';
import Localization from 'i18n';

const GridHeadRow = styled.div`
  display: flex;
  height: 45px;
  background-color: ${palette.navy[1]};
  border-bottom: 1px solid ${palette.navy[3]};
  text-transform: uppercase;
  color: ${palette.grey[0]};
  font-weight: 700;
  & > div {
    justify-content: center;
  }
`;
const GridRowWrap = styled.div`
  min-height: 600px;
  background-color: ${palette.navy[2]};
`;
const GridRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: inherit;
  border-bottom: 1px solid ${palette.navy[0]};
  color: ${palette.grey[1]};
  cursor: pointer;
  :hover {
    background-color: ${palette.navy[0]};
  }
  ${(props) => props.selected
    && `
    background: linear-gradient(to right, ${palette.primary.blue} 0, ${palette.primary.blue} 5px, ${palette.navy[0]} 5px, ${palette.navy[0]} calc(100% - 5px), ${palette.navy[0]} 100%);
  `}
`;
const GridCell = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.w};
  height: inherit;
  font-size: 13px;
  font-family: ${fontEN};
  letter-spacing: 0.05em;
  ${(props) => props.w !== '100%' && 'flex-shrink: 0;'}
  ${(props) => props.theme === 'name'
    && `
    font-weight: 500;
    font-size: 14px;
    color: ${palette.primary.white};
    font-family: ${fontKR};
  `}
  ${(props) => props.theme === 'links'
    && `
    font-weight: 700;
    justify-content: flex-start;
    padding-left: 10px;
    color: ${palette.primary.white};

    span {
      white-space: nowrap;
      margin-right: 20px;
      color: inherit;
      font-family: inherit;
      font-weight: 700;
      :hover {
        text-decoration: underline;
      }
    }
  `}
  padding: 16.5px 0;
`;
const PlusButton = styled.button`
  height: 100%;
  :hover svg path {
    stroke: ${palette.hover.blue};
  }
`;
const GridEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  border-bottom: 1px solid ${palette.navy[0]};
  font-size: 13px;
  color: ${palette.grey[1]};
  text-transform: capitalize;
`;
const SortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  margin: 0 -22px 0 4px;
  color: inherit;
  font-weight: inherit;
  text-transform: inherit;
  svg {
    margin-left: 4px;
  }
`;

const HistoryButton = styled.button`
  &:hover {
    text-decoration: underline;
  }
`;

const getAge = (birthDate) => {
  const diff = new Date() - new Date(birthDate).getTime();
  const age = Math.floor(diff / 3.15576e10);
  return age;
};

const GridRowItem = ({
  data,
  historyData,
  pushViewerPage,
  onClick,
  onEdit,
  deletePatient
}) => {
  const globalState = useContext(GlobalContext);
  const popupState = useContext(PopupContext);

  const handleClickRow = (e, id, chartID) => {
    if (onClick) {
      onClick(id, chartID);
    }
    e.stopPropagation();
  };

  const handleViewPage = (id) => {
    pushViewerPage(id);
    globalState.update({ viewPatientID : data.id });
  };

  const handleDelete = (id) => {
    popupState.update({
      display: true,
      title: Localization.delete_patient,
      text: Localization.click_delete_patient,
      textConfirm: Localization.confirm,
      textCancel: Localization.cancel,
      fnConfirm: () => {
        deletePatient(id);
      }
    });
  };

  const clickRow = (e) => {
    if (historyData.history.length <= 0) {
      popupState.update({
        display: true,
        title: Localization.chart_widthout_data,
        textConfirm: Localization.confirm
      });
    } else {
      handleClickRow(e, historyData.history[0].attributes.uuid, historyData.history[0].id);
      globalState.update({ viewPatientID : data.id });
    }
  };
  
  return (
    <GridRow>
      <GridCell onClick={(e) => clickRow(e)} w="140px">{data.attributes.patient_id}</GridCell>
      <GridCell onClick={(e) => clickRow(e)} w="100px" theme="name">
        {data.attributes.first_name}
      </GridCell>
      <GridCell onClick={(e) => clickRow(e)} w="140px">
        {data.attributes.birth_info} ({getAge(data.attributes.birth_info)}Y)
      </GridCell>
      <GridCell onClick={(e) => clickRow(e)} w="70px">{data.attributes.gender ? 'F' : 'M'}</GridCell>
      <GridCell w="100%" theme="links">
        {historyData
          && historyData !== undefined
          && historyData.history.length > 0
          && historyData.history.map((value, index) => (
            <HistoryButton key={index} onClick={() => handleViewPage(value.attributes.uuid)}>
              <span>
                {value.attributes.ddhaim_chart_data.data.length > 0
                  ? value.attributes.ddhaim_chart_data.data[0].attributes
                    .shoot_date : '----'}
              </span>
            </HistoryButton>
          ))}
      </GridCell>
      <GridCell
        w="70px"
        onClick={(e) => {
          e.stopPropagation();
          onEdit(data.id);
        }}
      >
        <PlusButton type="button">
          <Edit />
        </PlusButton>
      </GridCell>
      <GridCell
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(data.id);
        }}
        w="70px"
      >
        <PlusButton type="button">
          <DeleteIcon />
        </PlusButton>
      </GridCell>
    </GridRow>
  );
};

const BoardBody = ({
  state,
  listHeadData,
  data,
  historyData,
  pushViewerPage,
  onClick,
  onEdit,
  onSort,
  sortingOrder,
  sortingItem,
  deletePatient
}) => {

  return (
    <>
      <GridHeadRow>
        {listHeadData.map((d, i) => (
          <GridCell key={i} w={d.width}>
            {d.sortable ? (
              <SortButton
                type="button"
                onClick={() => {
                  onSort(d.sortValue);
                }}
              >
                {d.label}{' '}
                <IconSort
                  theme={
                    d.sortValue === sortingItem
                    && (sortingOrder === 'desc' ? 'desc' : 'asc')
                  }
                />
              </SortButton>
            ) : (
              d.label
            )}
          </GridCell>
        ))}
      </GridHeadRow>
      <GridRowWrap>
        {state === 'loading' || state === 'error' ? (
          <GridEmpty>{state}</GridEmpty>
        ) : data.length === 0 ? (
          <GridEmpty>There is no result.</GridEmpty>
        ) : (
          data.map((d, i) => (
            <GridRowItem
              key={i}
              deletePatient={deletePatient}
              data={d}
              historyData={historyData[i]}
              pushViewerPage={pushViewerPage}
              onClick={(id, chartID) => onClick(id, chartID)}
              onEdit={onEdit}
            />
          ))
        )}
      </GridRowWrap>
    </>
  );
};

export default BoardBody;
