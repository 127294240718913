import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { palette, size, zIndex } from 'constants/styles';

const ListWrapper = styled.div`
  position: relative;
  width: ${size.minWidth}px;
  margin: 0 auto;
  padding: 10px 0 40px;
  min-height: calc(100vh - ${size.header + size.footer}px);
  @media screen and (max-width: ${size.portrait}px) {
    max-width: ${size.portrait - 50}px;
  }
`;
const BoardWrapper = styled.div`
  min-height: 772px;
`;
const ModalWrapper = styled.div`
  position: ${(props) => props.pos};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${size.minWidth}px;
  min-height: ${size.minHeight}px;
  background-color: ${palette.semantic.gray['3']};
  z-index: ${zIndex.floating};
  overflow-y: auto;
`;
const ModalInner = styled.div`
  width: ${size.minWidth}px;
  @media screen and (max-width: ${size.portrait}px) {
    margin: 0 auto;
  }
`;

const ListComponent = ({ children, mode, onClose }) => {
  const [position, setPosition] = useState('fixed');
  const modalBox = useRef(null);
  const handleClose = (e) => {
    if (!(!modalBox.current || modalBox.current.contains(e.target))) onClose();
  };

  useEffect(() => {
    (async () => { 
      try {
        if (mode === 'modal' && modalBox.current !== null) {
          const h = modalBox.current.getBoundingClientRect().height;
          if (window.innerHeight < h) {
            setPosition('absolute');
          } else {
            document.querySelector('body').style.overflow = 'hidden';
          }
        }
        return () => {
          document.querySelector('body').style.overflow = '';
        };    
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  if (mode === 'modal') {
    return (
      <ModalWrapper pos={position} onClick={handleClose}>
        <ModalInner ref={modalBox}>
          {children}
        </ModalInner>
      </ModalWrapper>
    );
  }
  return (
    <ListWrapper>
      <BoardWrapper>{children}</BoardWrapper>
    </ListWrapper>
  );
};

export default ListComponent;
