import { createContext, useState } from 'react';

export const ViewerContext = createContext({});

const ViewerInit = {
  state: '', // Adjustment, Profilogram, VTO/STO, Superimposition
  start: null,
  moving: false, // landmark moving
  cepproId: '', // cp_ceppro id(using graphql)
  originData: [], // origin landmark data(result of AI)
  existingData: {
    landmark: [],
    profilogram: {},
    vtosto: {}
  }, // last modified Data
  data: [], // landmark data
  refineData: [], // refinement landmark data
  size: {}, // upload img size
  facialImgSize: {},
  currentSize: 0, // display img size
  facialPhoto: null, // facial photo url
  file: null, // upload file
  autoAlign: {
    x: 0,
    y: 0,
  }, // autoAlign data
  treatment: false, // VTO/STO Treatment
  autoPrediction: false, // VTO/STO autoPrediction
  refinement: false, // VTO/STO Refinement
  treatmentPoint: [], // VTO/STO Treatment Point(moving & rotation)
  splitPoint: {
    upper: {},
    footer: {},
    ansPnsU1: {},
    ansPnsL: {},
    coronoid: {}
  }, // VTO/STO split point coordinate
  affectSvgTransform: {}, // VTO/STO Treatment affectSvg transform data
  morph: { state: false, url: '' }, // VTO/STO Morph
  xiPoint: {}, // use it to find the xi points
  dcPoint: {}, // dc-point
  isXiPoint: false,
  isDcPoint: false,
  profilogramStandard: {
    line: 'SN plane',
    point: 'Sella',
    scale: 100,
    rotate: 0
  }, // Profilogram Standard Line & Point
  setting: {}, // basic setting value
  imgTransform: { scale: 1, translate: [0, 0] }, // value of cephalo image transform(using drawing canvas transform)
  menu: [
    {
      name: 'X-ray',
      checked: true
    },
    {
      name: 'Landmark',
      checked: true
    },
    {
      name: 'Tracing line',
      checked: true
    },
    {
      name: 'Ref. plane',
      checked: true
    }
  ], // left menu
  brightness: 100, // using cephalo image brightness
  contrast: 100, // using cephalo image contrast
  reset: false,
  adjustmentUndoHistory: { undo: [], redo: [], init: null },
  vtostoMovement: [
    { name: 'ANS', Horz: 0, Verz: '1' },
    { name: 'PNS', Horz: 0, Verz: '2' },
    { name: 'Mx1 Crown', Horz: 0, Verz: '3' },
    { name: 'Mx6 Distal', Horz: 0, Verz: '4' },
    { name: 'Mn1 Crown', Horz: 0, Verz: '5' },
    { name: 'Mn6 Distal', Horz: 0, Verz: '6' },
    { name: 'B-Point', Horz: 0, Verz: '7' },
    { name: 'Pogonion', Horz: 0, Verz: '8' },
    { name: 'Menton', Horz: 0, Verz: '9' }
  ]
};

export function useViewerState() {
  const [viewerState, setViewerState] = useState(ViewerInit);

  const updateViewerState = (update) => {
    setViewerState((prev) => ({ ...prev, ...update }));
  };

  return {
    ...viewerState,
    update: updateViewerState
  };
}
