import { gql } from '@apollo/client';

export const CREATE_CHART_MUTATION = gql`
  mutation CreateDdhaimChart($data: DdhaimChartInput!) {
    createDdhaimChart(data: $data) {
      data {
        id
        attributes {
          ddhaim_patient {
            data {
              id
              attributes {
                first_name
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_CHART_MUTATION = gql`
  mutation UpdateDdhaimChart($updateDdhaimChartId: ID!, $data: DdhaimChartInput!) {
    updateDdhaimChart(id: $updateDdhaimChartId, data: $data) {
      data {
        id
        attributes {
          chart_id
          deletedAt
          uuid
          createdAt
          updatedAt
        }
      }
    }
  } 
`;

export const DELETE_DDHAIM_CHART = gql`
mutation Mutation($deleteDdhaimChartId: ID!) {
  deleteDdhaimChart(id: $deleteDdhaimChartId) {
    data {
      id
    }
  }
}
`;

export const UPDATE_CHARTDATA_MUTATION = gql`
mutation UpdateDdhaimChartData($data: DdhaimChartDataInput!, $updateDdhaimChartDataId: ID!) {
  updateDdhaimChartData(data: $data, id: $updateDdhaimChartDataId) {
    data {
      attributes {
        image_type_cd
        deletedAt
      }
    }
  }
}
`;

export const GET_CHARTDATA_LIST = gql`
query Data($filters: DdhaimChartFiltersInput) {
  ddhaimCharts(filters: $filters) {
    data {
      id
      attributes {
        ddhaim_chart_data {
          data {
            id
            attributes {
              image_type_cd
              deletedAt
            }
          }
        }
      }
    }
  }
}
`;
