export const USER_TYPE = {
  doctor: { text: '닥터', code: '00000' },
  hospital: { text: '병원', code: '00001' }
};

export const GENDER_TYPE = {
  m: { code: 0, text: '남자' },
  f: { code: 1, text: '여자' }
};

export const ANALYSIS_LINE_TYPE = {
  oneLine: [0, 2, 10, 11, 14, 19],
  manyLine: [1, 12, 13, 15, 16, 17, 18, 22, 23],
  dot: [6, 9],
  lineDot: [7],
  PRDIST: [5, 8],
  PFHAFH: [20, 21],
  vdist: [3, 25],
  hdist: [4, 24], 
};