import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { palette } from 'constants/styles';

const Button = styled.button`
  width: ${props => (props.theme === 'small' ? '100%' : props.theme === 'transparent' || props.theme === 'print' ? '140px' : '185px')};
  height: ${props => (props.theme === 'small' ? '25px' : '40px')};
  padding: 0 10px;
  border-radius: 20px;
  color: ${palette.primary.white};
  background-color: ${props => (props.theme === 'small' ? '#585C72' : props.theme === 'transparent' ? 'transparent' : palette.primary.blue)};
  &:hover:not(:disabled) {
    background-color: ${props => (props.theme !== 'small' && props.theme === 'transparent' ? palette.grey['5'] : palette.hover.blue)};
  }
  &:disabled {
    color: ${palette.grey['4']};
    background-color: ${palette.grey['5']};
  }
`;
const RoundButton = (props) => (
  <Button {...props}>{props.children}</Button>
);

RoundButton.prototype = {
  disabled: PropTypes.bool,
  theme: PropTypes.oneOf([null, 'small']),
  onClick: PropTypes.func,
  children: PropTypes.node,
};

RoundButton.defaultProps = {
  disabled: false,
  theme: null,
  onClick: undefined,
  children: undefined,
};

export default RoundButton;