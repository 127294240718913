import React, { useEffect, useState } from 'react';
import { HashRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { GlobalStyles } from 'constants/styles';
import AppContainer from 'containers/common/AppContainer';
import Routes from 'routes';
import Analytics from 'react-router-ga';
import 'react-custom-scroll/dist/customScroll.css';
import ReactGA from 'react-ga4';

export const history = createBrowserHistory();

const App = () => {
  const [initialized, setInitialized] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOfflineStatus = () => setIsOnline(false);
    const handleOnlineStatus = () => setIsOnline(true);

    window.addEventListener('offline', handleOfflineStatus);
    window.addEventListener('online', handleOnlineStatus);

    return () => {
      window.removeEventListener('offline', handleOfflineStatus);
      window.removeEventListener('online', handleOnlineStatus);
    };
  }, [isOnline]);

  // google analystic 관련 코드
  // localhost는 기록하지 않음
  useEffect(() => {
    try {
      if (!window.location.href.includes('localhost')) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
        setInitialized(true);
      }

    } catch (error) {
      console.log(error);
    }
  }, []);

  history.listen((update) => { 
    setInitialized(true);
    if (initialized) {
      ReactGA.send({ hitType: 'pageview', page: update.location.hash });
    }
  });

  window.addEventListener(
    'dragover',
    (e) => {
      if (e.target.tagName !== 'INPUT') {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    false
  );

  window.addEventListener(
    'drop',
    (e) => {
      if (e.target.tagName !== 'INPUT') {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    false
  );

  return (
    <>
      {isOnline ? (
        <HashRouter history={history}>
          <Analytics id="UA-257186962-1" debug>
            <GlobalStyles />
            <AppContainer>
              <Routes />
            </AppContainer>
          </Analytics>
        </HashRouter>
      )
        : (
          <div className="offline-notification">
            <p>You are currently offline. Please check your internet connection.</p>
          </div>
        )}
      
    </>
  );
};

export default App;

// remove console.log on product mode
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}
