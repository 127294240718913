import React from 'react';
import styled from 'styled-components';
import { palette, size, zIndex } from 'constants/styles';
import Spinner from 'assets/spinner.gif';

export const Background = styled.div`
  background: #ffffff;
  opacity: 0.5;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${size.minWidth}px;
  min-height: ${size.minHeight}px;
  z-index: ${zIndex.modalPopup};
`;

export const LoadingText = styled.div`
  font: 1rem 'Noto Sans KR';
  text-align: center;
  color: ${palette.primary.blue};
  `;

export const Loading = ({ width }) => {
  return (
    <Background width={width || 100}>
      {/* <LoadingText>리스트를 불러오는 중입니다.</LoadingText> */}
      <img src={Spinner} alt="로딩중" width="7%" />
    </Background>
  );
};

export default Loading;