import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import styled from 'styled-components';

import IconEye from 'assets/icons/Eye';
import IconCloseEye from 'assets/icons/CloseEye';
import IconCalendar from 'assets/icons/Calendar';
import Checkbox from 'components/forms/Checkbox';
import { palette, fontEN } from 'constants/styles';
import { ShowButton } from 'components/layout/LoginLayout';
import { GlobalContext } from 'modules/context/GlobalContext';
import Localization from 'i18n';

const FormItemWrapper = styled.div`
  width: 220px;
  height: 64px;
  & + & {
    margin-left: 55px;
  }
`;
const ErrorMesage = styled.div`
  height: 17px;
  color: ${palette.semantic.orange};
  font-size: 12px;
  margin-top: 4px;
  ${(props) => props.theme === 'login'
    && `
    margin-left: 88px;
  `}
`;
const Label = styled.div`
  height: 19px;
  font-size: 13px;
  color: ${palette.grey['4']};
  line-height: 19px;
  margin-bottom: 2px;
`;

const Input = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => props.name === 'birth' && '100%'};
  ${(props) => (props.menu === 'superimposition'
    ? `
    justify-content: center;
    width: 80%;
    margin: -25px auto 0 auto;
  `
    : null)}
  ${(props) => (props.menu !== 'superimposition'
    ? `border-bottom: 1px solid ${
      props.focus === 'true' ? palette.primary.white : palette.grey[4]
    };`
    : 'null')};
  ${(props) => props.readonly === 'readonly'
    && `
    border-bottom-width: 0;
  `}

  input {
    width: 100%;
    height: 21px;
    font-family: ${fontEN};
    background-color: transparent;
    color: ${palette.primary.white};
    font-size: 14px;
    text-indent: 4px;
    border: none;
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    :disabled {
      color: ${palette.grey[4]};
    }
    :read-only {
      color: ${palette.grey[1]};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
const DatePickerWrapper = styled.div`
  width: 100%;
  color: ${palette.primary.white};
  div {
    width: 100%;
    border: 0;
  }
  .react-calendar {
    position: absolute;
    margin-top: 10px;
    button:disabled {
      background-color: transparent;

      abbr {
        color: ${palette.grey['5']};
      }
    }
  }
  
  .react-date-picker__calendar--closed {
    display: none;
  }
`;

const CheckWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 7px;

  & > div + div {
    margin-left: 25px;
  }
`;
const LineButtonWrapper = styled.button`
  height: 19px;
  color: ${(props) => (props.theme === 'white'
    ? palette.grey[1]
    : props.theme === 'gray'
      ? palette.grey[4]
      : palette.primary.blue)};
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  & + & {
    margin-left: 5px;
  }
  svg {
    vertical-align: middle;
    margin-top: -3px;
  }
  :hover:not(:disabled) {
    color: ${(props) => (props.theme === 'white'
    ? palette.primary.white
    : props.theme === 'gray'
      ? palette.grey[4]
      : palette.hover.blue)};
  }
  :disabled {
    color: ${palette.grey[5]};
  }
`;
export const LineButton = (props) => (
  <LineButtonWrapper type="button" {...props}>
    {props.children}
  </LineButtonWrapper>
);

export const RequestTimer = styled.div`
  position: absolute;
  right: 0;
  color: ${palette.grey['2']};
  font-size: 10px;
`;

let locale;
const FormItem = ({
  children,
  theme,
  label,
  type,
  name,
  menu,
  value,
  maxLength,
  disabled,
  readonly,
  placeholder,
  onChange,
  onBlur,
  onDateChange,
  onKeyDown,
  errorMessage,
  onSelectOne,
  selectedOne,
  selectOneList,
  hidePasswordToggle = false,

}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [formType, setFormType] = useState(type);
  const [formValue, setFormValue] = useState(value);
  const [checked, setChecked] = useState(selectedOne);
  const [focus, setFocus] = useState(false);
  const [datePickerLocale, setDatePickerLocale] = useState(locale);
  const globalContext = useContext(GlobalContext);

  // form onChange
  const handleChange = (e) => {
    // 핸드폰 번호 입력 제한 11자리
    if (
      onDateChange === undefined // 넣어주지 않으면 DatePicker때문에 오류 발생
      && e.target.name === 'mobileNumber'
      && e.target.value.length > 11
    ) {
      e.target.value = '';
      return;
    }

    if (onChange) {
      onChange(e);
      setFormValue(e.target.value);
    } else if (onDateChange) {
      onDateChange(e);
    }
  };

  const handleBlur = (e) => {
    if (onBlur) onBlur(e);
    setFocus(false);
  };

  // selectOne click
  const handleCheckBoxChange = (e) => {
    if (onSelectOne) onSelectOne(e);
  };

  const handleFocus = () => {
    setFocus(true);
  };

  useEffect(() => {
    setShowPassword(false);
  }, []);

  useEffect(() => {
    try {
      setFormValue(value);
    } catch (error) {
      console.log(error);
    }
  }, [value]);

  useEffect(() => {
    try {
      setFormType(type);
    } catch (error) {
      console.log(error);
    }
  }, [type]);

  useEffect(() => {
    try {
      setChecked(selectedOne);
    } catch (error) {
      console.log(error);
    }
  }, [selectedOne]);

  useEffect(() => {
    try {
      if (globalContext.langIndex === 0) {
        locale = 'ko';
      } else if (globalContext.langIndex === 1) {
        locale = 'en';
      } else {
        locale = 'ja';
      }
      setDatePickerLocale(locale);
    } catch (error) {
      console.log(error);
    }
  }, [globalContext.langIndex]);

  if (theme === 'empty') return <FormItemWrapper />;

  return (
    <FormItemWrapper>
      <Label>{label}</Label>
      {type === 'selectOne' ? (
        <>
          <CheckWrapper>
            {selectOneList
              && Object.entries(selectOneList).map((d, index) => (
                <Checkbox
                  key={index}
                  type="radio"
                  name={name}
                  value={d[1].code}
                  checked={checked === d[1].code}
                  onChange={handleCheckBoxChange}
                  disabled={disabled}
                >
                  {name === 'gender'
                    ? d[1].text === '남자'
                      ? Localization.male
                      : Localization.female
                    : d[1].text}
                </Checkbox>
              ))}
            {children && children}
          </CheckWrapper>
          <ErrorMesage>{errorMessage && errorMessage}</ErrorMesage>
        </>
      ) : type === 'date' ? (
        <>
          <Input
            disabled={disabled}
            readonly={readonly}
            focus={!readonly && focus.toString()}
            theme={name}
            menu={menu}
          >
            <DatePickerWrapper>
              <DatePicker
                locale={datePickerLocale}
                onChange={handleChange}
                value={value}
                calendarIcon={<IconCalendar />}
                defaultActiveStartDate={
                  name === 'birth'
                    ? new Date(
                      new Date().setFullYear(
                        new Date().getFullYear() - 25
                      )
                    )
                    : new Date(
                      new Date().setFullYear(new Date().getFullYear())
                    )
                }
                format="yyyy / MM / dd"
                formatDay={(locale, date) => date.toLocaleString('en', { day: 'numeric' })}
                maxDate={new Date()}
                minDate={new Date('1900-01-01')}
                disabled={disabled}
                clearIcon={null}
                next2Label={null}
                prev2Label={null}
                dayAriaLabel="Day"
              />
            </DatePickerWrapper>

            {children && children}
          </Input>
          <ErrorMesage>{errorMessage && errorMessage}</ErrorMesage>
        </>
      ) : (
        <>
          <Input
            disabled={disabled}
            readonly={readonly}
            focus={!readonly && focus.toString()}
            menu={menu}
          >
            <input
              type={
                type === 'authNumber' || type === 'phone'
                  ? 'number'
                  : formType
              }
              name={name}
              value={formValue}
              maxLength={maxLength}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
              readOnly={readonly}
              placeholder={placeholder}
              onFocus={handleFocus}
              autoComplete="off"
              onKeyDown={(e) => onKeyDown && onKeyDown(e)}
            />
            {type === 'password' && !readonly && !hidePasswordToggle && (
              <ShowButton
                type="button"
                active={showPassword}
                onClick={() => {
                  setShowPassword(!showPassword);
                  if (showPassword) setFormType('password');
                  else setFormType('text');
                }}
              >
                {showPassword ? (
                  <IconEye color={palette.primary.blue} />
                ) : (
                  <IconCloseEye />
                )}
              </ShowButton>
            )}
            {children && children}
          </Input>
          <ErrorMesage>{errorMessage && errorMessage}</ErrorMesage>
        </>
      )}
    </FormItemWrapper>
  );
};

FormItem.protoType = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onDateChange: PropTypes.func,
  errorMessage: PropTypes.string,
  onSelectOne: PropTypes.func,
  selectOne: PropTypes.bool,
  selectOneList: PropTypes.object,
  theme: PropTypes.string,
  children: PropTypes.any
};

export default FormItem;

export const FormWrapper = styled.div`
  margin: 20px 0 43px;
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  & + & {
    margin-top: 23px;
    margin-bottom: 45px;
  }
`;
