import React, { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router';

import AppLayout from 'components/layout/AppLayout';
import ToastComponent from 'components/common/Toast';
import PopupContainer from 'containers/common/PopupContainer';
import FooterContainer from 'containers/common/FooterContainer';
import HeaderContainer from 'containers/common/HeaderContainer';
import ListContainer from 'containers/chart/ListContainer';
import ViewContainer from 'containers/chart/EditContainer';
import CreateContainer from 'containers/chart/CreateContainer';
import ChangeContainer from 'containers/chart/ChangeContainer';
import { PopupContext, usePopupState } from 'modules/context/PopupContext';
import { ToastContext, useToastState } from 'modules/context/ToastContext';
import { GlobalContext, useGlobalState } from 'modules/context/GlobalContext';
import { pagePaths } from 'constants/paths';
import CookieBannerContainer from 'containers/common/CookieBannerContainer';
import { useViewerState, ViewerContext } from 'modules/context/ViewerContext';
import { useCookies } from 'react-cookie';
import { DrawContext, useDrawState } from 'modules/context/DrawContext';
import { authAPI } from 'modules/api/auth';
import Localization from 'i18n';

const ModalContainers = (mode) => {
  switch (mode) {
  case 'list':
    return <ListContainer mode="modal" />;
  case 'view':
    return <ViewContainer mode="modal" />;
  case 'create':
  case 'add':
    return <CreateContainer mode="modal" />;
  case 'change':
    return <ChangeContainer mode="modal" />;
  case 'view/change':
    return (
      <>
        <ViewContainer mode="modal" />
        <ChangeContainer mode="modal" />
      </>
    );
  default:
    return null;
  }
};

const AppContainer = ({ location, children }) => {
  const cookies = useCookies();
  const history = useHistory();
  const popupState = usePopupState();
  const toastState = useToastState();
  const globalState = useGlobalState();
  const viewerState = useViewerState();
  const drawState = useDrawState();
  const isViewerPage = location.pathname.split('/')[1] === pagePaths.viewer.split('/')[1];

  useEffect(() => {
    try {
      if (
        !cookies[0].token
        && (location.pathname !== pagePaths.home
          || location.pathname !== pagePaths.join
          || location.pathname !== pagePaths.findEmail
          || location.pathname !== pagePaths.findPassword)
      )
        return () => history.push(pagePaths.home);
    } catch (error) {
      console.log(error);
    }
  }, [cookies[0]]);

  useEffect(() => {
    try {
      globalState.update({ displayModal: false });
      viewerState.update({
        state: '',
        treatment: false,
        refinement: false,
        facialPhoto: null,
        facialImg: null,
        morph: { state: false, url: '' }
      });
      drawState.clear();
    } catch (error) {
      console.log(error);
    }
  }, [location]);

  useEffect(() => {
    try {
      const Interval = setInterval(() => {
        if (globalState.user !== undefined) {
          authAPI
            .loginCheck(cookies)
            .then(() => {
            })
            .catch((error) => {
              if (error.error_code === 'J005') {
                popupState.update({
                  display: true,
                  title: Localization.duplicate_login_user,
                  text: Localization.logout_3seconds
                });
                setTimeout(() => {
                  const removeCookie = cookies[2];
                  removeCookie('token');
                  removeCookie('i');
                  removeCookie('refreshToken');
                  globalState.update({ user: {} });
                  history.push(pagePaths.home);
                  popupState.update({
                    display: false
                  });
                  window.location.reload();
                }, 3000);
              }
            });
        }
      }, 60000);
      return () => clearInterval(Interval);
    } catch (error) {
      console.log(error);
    }
  }, [globalState]);

  return (
    <PopupContext.Provider value={popupState}>
      <ToastContext.Provider value={toastState}>
        <GlobalContext.Provider value={globalState}>
          <ViewerContext.Provider value={viewerState}>
            <DrawContext.Provider value={drawState}>
              <AppLayout>
                <HeaderContainer />
                <div>
                  {children}
                  <CookieBannerContainer />
                  {!isViewerPage && <FooterContainer />}
                  <ToastComponent />
                  {globalState.displayModal
                    && ModalContainers(globalState.modalMode)}
                  <PopupContainer
                    state={popupState}
                    onClear={() => popupState.clear()}
                  />
                </div>
              </AppLayout>
            </DrawContext.Provider>
          </ViewerContext.Provider>
        </GlobalContext.Provider>
      </ToastContext.Provider>
    </PopupContext.Provider>
  );
};

export default withRouter(AppContainer);
