import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Login, Join, Find, List, View, Create, Viewer, Setting } from 'pages';
import { pagePaths } from 'constants/paths';
import Test from 'pages/Test';
import TestToast from 'pages/TestToast';

const Routes = () => (
  <Switch>
    <Route path={pagePaths.home} component={Login} exact />
    <Route path={pagePaths.join} component={Join} />
    <Route path={pagePaths.findEmail} component={Find} />
    <Route path={pagePaths.findPassword} component={Find} />
    <Route path={pagePaths.list} component={List} />
    <Route path={pagePaths.view} component={View} />
    <Route path={pagePaths.create} component={Create} />
    <Route path={pagePaths.viewer} component={Viewer} />
    <Route path={pagePaths.setting} component={Setting} />
    <Route path={pagePaths.drawingPad} component={Setting} />
    <Route path={pagePaths.membership} component={Setting} />
    <Route path={pagePaths.myInfo} component={Setting} />
    <Route path={pagePaths.resetEmail} component={Setting} />
    <Route path={pagePaths.resetPassword} component={Setting} />
    <Route path={pagePaths.resetPhone} component={Setting} />
    <Route path="/test" component={Test} />
    <Route path="/toast" component={TestToast} />
    <Route component={() => <Redirect to={pagePaths.home} />} />
  </Switch>
);

export default Routes;
