import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { palette } from 'constants/styles';

const Button = styled.button`
  min-width: 100px;
  height: 25px;
  padding: 0 10px;
  border-radius: 5px;
  font-size: 13px;
  white-space: nowrap;
  color: ${palette.primary.white};
  background-color: ${props => (props.theme === 'black' ? palette.semantic.gray['5'] : palette.primary.blue)};
  &:hover:not(:disabled) {
    background-color: ${props => (props.theme === 'black' ? palette.grey['5'] : palette.hover.blue)};
  }
`;
const ThinButton = (props) => (
  <Button {...props}>{props.children}</Button>
);

ThinButton.propTypes = {
  disabled: PropTypes.bool,
  theme: PropTypes.oneOf([null, 'black']),
  onClick: PropTypes.func,
  children: PropTypes.node,
};

ThinButton.defaultProps = {
  disabled: false,
  theme: null,
  onClick: undefined,
  children: undefined,
};

export default ThinButton;