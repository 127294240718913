import React, { useContext } from 'react';
import styled from 'styled-components';

import { PopupContext } from 'modules/context/PopupContext';
import PanoLogo from 'assets/logo-pano.svg';

const BodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 170px);
`;
const Board = () => {
  const popupState = useContext(PopupContext);

  const handleOpenModal = () => {
    popupState.update({
      display: true,
      title: '구독을 취소하시겠습니까?',
      text: `청구일까지 이용이 가능합니다.<br />
        다음 결제시 <img src=${PanoLogo} alt="ddhaim pano" style="vertical-align:middle;margin:0 4px;" /> 를 제외한<br />
        50,000원이 청구됩니다`,
      textConfirm: '계속 이용하기',
      textCancel: '구독취소',
      handleConfirm: () => { console.log('click confirm'); },
      handleCancel: () => { console.log('click cancel'); },
    });
  };

  return (
    <BodyWrapper>
      <button type="button" onClick={handleOpenModal}>View Modal Popup</button>
    </BodyWrapper>
  );
};

export default Board;
