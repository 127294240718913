import { createContext, useEffect, useState } from 'react';

export const ToastContext = createContext({});

const ToastInit = [];

export function useToastState() {
  const [toastDisplay, setToastDisplay] = useState(false);
  const [toastTheme, setToastTheme] = useState('basic');
  const [toastState, setToastState] = useState(ToastInit);

  useEffect(() => {
    try {
      if (JSON.parse(sessionStorage.getItem('toast')) !== null) {
        setToastState(JSON.parse(sessionStorage.getItem('toast')));
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  
  const updateToastDisplay = (update) => {
    setToastDisplay(update);
  };

  const updateToastTheme = (update) => {
    setToastTheme(update);
  };

  const updateToastState = (update) => {    
    const isUpdateIncluded = toastState.some(item => (
      item.kind === 'warning'
      && item.kind === update.kind
      && item.title === update.title
      && item.text === update.text
    ));
    if (!isUpdateIncluded) {
      setToastState(prev => [...prev, update]);
    } else {
      setToastState([...toastState]);
    }
    const previousData = JSON.parse(sessionStorage.getItem('toast')) || [];
    const combinedData = [...previousData, update];
    const exceptSystemNoticeValue = combinedData.reduce((e, i) => {
      const value = JSON.stringify(i);    
      if (!e.includes(value)) {
        e.push(value);
      }    
      return e;
    }, []);
    
    const uniqueSystemNotices = exceptSystemNoticeValue.map(JSON.parse);
    sessionStorage.setItem('toast', JSON.stringify(uniqueSystemNotices));
  };
  
  const deleteToastState = (index) => {
    setToastState(toastState.filter(prev => toastState.indexOf(prev) !== index));
    sessionStorage.setItem('toast', JSON.stringify(toastState.filter(prev => toastState.indexOf(prev) !== index)));
  };

  const clearToastState = () => {
    setToastState(ToastInit);
    sessionStorage.removeItem('toast');
  };
  
  return {
    display: updateToastDisplay,
    theme: updateToastTheme,
    update: updateToastState,
    delete: deleteToastState,
    toastState,
    toastDisplay,
    toastTheme,
    clear: clearToastState
    // clear: () => setToastState(ToastInit)
  };
}