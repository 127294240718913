import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { palette } from 'constants/styles';

const Button = styled.button`
  min-width: ${(props) => (props.size === 'large'
    ? '300px'
    : '145px')};
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
  color: ${(props) => (props.theme === 'white' ? palette.background.black : palette.primary.white)};
  background-color: ${(props) => (props.theme === 'white'
    ? palette.primary.white
    : palette.primary.blue)};
  &:hover:not(:disabled) {
    color: ${(props) => (props.theme === 'white' ? palette.primary.blue : palette.primary.white)};
    background-color: ${(props) => (props.theme === 'white' ? palette.primary.white : palette.hover.blue)};
  }
  &:disabled {
    color: ${palette.grey['4']};
    background-color: ${palette.grey['1']};
  }
`;
const DefaultButton = (props) => <Button {...props}>{props.children}</Button>;

DefaultButton.propTypes = {
  disabled: PropTypes.bool,
  theme: PropTypes.oneOf([null, 'white', 'modal']),
  size: PropTypes.oneOf(['middle', 'large']),
  onClick: PropTypes.func,
  children: PropTypes.node
};

DefaultButton.defaultProps = {
  disabled: false,
  theme: null,
  onClick: undefined,
  children: undefined
};

export default DefaultButton;
