import React from 'react';

const AsyncComponent = (importComponent) => {
  class asyncComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null,
      };
    }

    componentDidMount() {
      importComponent()
        .then(({ default: component }) => this.setState({ component }));
    }

    render() {
      const { component: Component } = this.state;
      return Component ? <Component {...this.props} /> : null;
    }
  }

  return asyncComponent;
};

export default AsyncComponent;