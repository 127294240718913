import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { palette, size } from 'constants/styles';

const RadioboxWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  color: ${(props) => (props.active ? palette.primary.white : palette.grey['3'])};
  ${(props) => props.theme === ''
    && props.active
    && `
    span {
      font-weight: 500;
    }
  `};
  ${(props) => props.theme === 'box'
    && `
    height: 45px;
    padding-left: 13px;
    color: ${props.active ? palette.primary.white : palette.grey['1']};
    &:hover {
      background-color: #585C72;
    }
  `}
  ${(props) => props.hover === 'black'
    && `
    &:hover {
      background-color: ${palette.grey['5']};
    }
  `}
  @media screen and (max-width: ${size.portrait}px) {
    white-space: nowrap;
    padding-right: 15px;    
  }
`;
const RadioInput = styled.span`
  flex-shrink: 0;
  display: block;
  width: 12px;
  height: 12px;
  padding: 2px;
  border-radius: 50%;
  ${(props) => props.active
    && `
    background-color: ${
  props.theme === 'box' ? palette.navy['0'] : palette.primary.white
};
  `}
  border: 1px solid ${(props) => (props.active ? palette.primary.blue : palette.grey['1'])};
  color: ${(props) => (props.active ? palette.primary.white : palette.grey['3'])};
  ${(props) => props.active
    && `
    &:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${palette.primary.blue};
    }
  `}
`;

const InputLabel = styled.span`
  margin-left: 8px;
  margin-right: 8px;
  font-size: 14px;
  color: inherit;
`;

const RadioButton = ({ children, checked, onClick, theme, hover }) => {
  const [active, setActive] = useState(checked);

  const handleClick = () => {
    if (onClick) onClick(!active);
  };

  useEffect(() => {
    try {
      setActive(checked);
    } catch (error) {
      console.log(error);
    }
  }, [checked]);

  return (
    <RadioboxWrapper
      active={active}
      theme={theme}
      hover={hover}
      onClick={() => handleClick()}
    >
      <RadioInput active={active} theme={theme} />
      {children && <InputLabel>{children}</InputLabel>}
    </RadioboxWrapper>
  );
};

RadioButton.propTypes = {
  children: PropTypes.node,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(['', 'box'])
};

RadioButton.defaultProps = {
  children: undefined,
  checked: false,
  onClick: undefined,
  theme: ''
};

export default RadioButton;
