import React from 'react';

const Error = ({ width, height, color }) => (
  <svg width={width || '16'} height={height || '16'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 1.45455C4.38505 1.45455 1.45455 4.38505 1.45455 8C1.45455 11.615 4.38505 14.5455 8 14.5455C11.615 14.5455 14.5455 11.615 14.5455 8C14.5455 4.38505 11.615 1.45455 8 1.45455ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" fill={color || '#FB3836'} />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.696 5.30347C10.98 5.58748 10.98 6.04797 10.696 6.33199L6.33235 10.6956C6.04833 10.9796 5.58785 10.9796 5.30383 10.6956C5.01982 10.4116 5.01982 9.95112 5.30383 9.6671L9.66747 5.30347C9.95149 5.01945 10.412 5.01945 10.696 5.30347Z" fill={color || '#FB3836'} />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.30383 5.30347C5.58785 5.01945 6.04833 5.01945 6.33235 5.30347L10.696 9.6671C10.98 9.95112 10.98 10.4116 10.696 10.6956C10.412 10.9796 9.95149 10.9796 9.66747 10.6956L5.30383 6.33199C5.01982 6.04797 5.01982 5.58748 5.30383 5.30347Z" fill={color || '#FB3836'} />
  </svg>

);

export default Error;