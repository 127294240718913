import { createContext, useState } from 'react';
import { useCookies } from 'react-cookie';

import { langsSelectList } from 'i18n';

export const GlobalContext = createContext({});

const initState = {
  displayModal: false,
  modalMode: 'list', // list | view | view/change(모달 위 모달) | create | add
  viewPatientID: '',
  user: {},
  langIndex: Math.max(
    Object.keys(langsSelectList).indexOf(
      window.localStorage.getItem('lang') || navigator.language
    ),
    0
  ),
  isRechecked: false,
  isLoading: false,
  rerender: false
};

export function useGlobalState() {
  const [cookies] = useCookies();
  const [globalState, setGlobalState] = useState({
    ...initState,
    user: cookies.i
  });

  const updateState = (update) => {
    setGlobalState((prev) => ({ ...prev, ...update }));
  };

  return {
    ...globalState,
    update: updateState
  };
}
