import React from 'react';

const PlusCircle = ({ width, height, color }) => (
  <svg width={width || '24'} height={height || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color || '#F1F1F1'} d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47713 17.5228 1.99998 12 1.99998C6.47715 1.99998 2 6.47713 2 12C2 17.5228 6.47715 22 12 22Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path stroke={color || '#F1F1F1'} d="M12 7.99998V16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path stroke={color || '#F1F1F1'} d="M8 12H16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default PlusCircle;