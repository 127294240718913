import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { palette } from 'constants/styles';
import IconCheck from 'assets/icons/Check';

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  color: ${(props) => (props.disabled ? palette.grey[5] : 'inherit')};
  font-size: 12px;
  font-weight: ${(props) => (props.checked ? '700' : '500')};
`;

const Box = styled.span`
  pointer-events: none;
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.theme === 'small'
    ? '12px'
    : props.theme === 'large'
      ? '20px'
      : '15px')};
  height: ${(props) => (props.theme === 'small'
    ? '12px'
    : props.theme === 'large'
      ? '20px'
      : '15px')};
  margin-right: 5px;
  margin-top: 2px;
  border: ${(props) => (props.disabled || props.checked ? '0' : `1px solid ${palette.grey['4']}`)};
  background-color: ${(props) => (props.disabled
    ? palette.grey['5']
    : props.checked
      ? palette.primary.blue
      : 'transparent')};
  border-radius: ${(props) => (props.theme === 'small' ? '4px' : '5px')};
  vertical-align: middle;
  svg {
    pointer-events: none;
    transform: scale(
      ${(props) => (props.theme === 'small'
    ? '0.8'
    : props.theme === 'large'
      ? '1.37'
      : '1')}
    );
    ${(props) => props.disabled
      && `
      path {
        stroke: ${palette.grey['4']};
      }
    `}
  }
`;

const Checkbox = (props) => {
  const { type, size, checked, name, value, children, disabled, onChange } = props;
  const [active, setActive] = useState(checked || false);
  const handleClick = (e) => {
    if (type === 'radio' && e.target.dataset.checked === 'true') return;
    if (disabled) return;
    setActive(!active);
    if (onChange) onChange(e);
  };
  useEffect(() => {
    try {
      setActive(checked);
    } catch (error) {
      console.log(error);
    }
  }, [checked]);
  
  return (
    <CheckboxWrapper
      onClick={handleClick}
      name={name}
      value={value}
      data-checked={active}
      theme={size}
      checked={checked}
      disabled={disabled}
    >
      <Box theme={size} checked={active} disabled={disabled}>
        {active && <IconCheck />}
      </Box>
      {children}
    </CheckboxWrapper>
  );
};

Checkbox.propsTypes = {
  size: PropTypes.oneOf(['small', null, 'large']),
  checked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};
Checkbox.defaultProps = {
  size: null,
  checked: false,
  children: undefined,
  disabled: false,
  onChange: undefined
};

export default Checkbox;
