import { gql } from '@apollo/client';

// MUTATION
export const CREATE_PATIENT_MUTATION = gql`
  mutation CreateDdhaimPatient($data: DdhaimPatientInput!) {
    createDdhaimPatient(data: $data) {
      data {
        id
        attributes {
          first_name
        }
      }
    }
  }
`;

export const UPDATE_PATIENT_INFO = gql`
mutation UpdateDdhaimPatient(
  $data: DdhaimPatientInput!
  $updateDdhaimPatientId: ID!
) {
  updateDdhaimPatient(data: $data, id: $updateDdhaimPatientId) {
    data {
      id
      attributes {
        birth_info
        first_name
        gender
        ddhaim_charts {
          data {
            id
          }
        }
      }
    }
  }
}
`;

// QUERY
export const GET_ONE_PATIENT_QUERY = gql`
  query DdhaimPatient($ddhaimPatientId: ID) {
    ddhaimPatient(id: $ddhaimPatientId) {
      data {
        id
        attributes {
          birth_info
          createdAt
          first_name
          gender
          mobile_nb
          race_cd
          updatedAt
          patient_id
        }
      }
    }
  }
`;

export const GET_ONE_PATIENT_OF_DOCTOR = gql`
  query DdhaimCharts($filters: DdhaimChartFiltersInput) {
    ddhaimCharts(filters: $filters) {
      data {
        id
        attributes {
          ddhaim_patient {
            data {
              attributes {
                gender
                first_name
                createdAt
                birth_info
                patient_id
              }
              id
            }
          }
        }
      }
    }
  }
`;

// 이건 내가 쓴 거
export const UPDATE_PATIENT = gql`
mutation UpdateDdhaimPatient($updateDdhaimPatientId: ID!, $data: DdhaimPatientInput!) {
  updateDdhaimPatient(id: $updateDdhaimPatientId, data: $data) {
    data {
      id
      attributes {
        birth_info
        first_name
        gender
      }
    }
  }
}
`;

export const GET_ALL_PATIENT_LIST_OF_DOCTOR = gql`
  query ExampleQuery(
    $pagination: PaginationArg
    $filters: DdhaimPatientFiltersInput
    $sort: [String]
    $ddhaimChartsFilters2: DdhaimChartFiltersInput
  ) {
    ddhaimPatients(pagination: $pagination, filters: $filters, sort: $sort) {
      data {
        id
        attributes {
          patient_id
          first_name
          birth_info
          gender
          createdAt
          ddhaim_charts (filters: $ddhaimChartsFilters2){
            data {
              id
              attributes {
                uuid
                chart_id
                createdAt
                ddhaim_chart_data {
                  data {
                    id
                    attributes {
                      image_type_cd
                      shoot_date
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const GET_ALL_CHART_LIST_OF_PATIENT = gql`
  query ExampleQuery($filters: DdhaimChartFiltersInput, $sort: [String]) {
    ddhaimCharts(filters: $filters, sort: $sort) {
      data {
        attributes {
          uuid
          createdAt
          ddhaim_chart_data {
            data {
              id
              attributes {
                createdAt
                path
                image_type_cd
                shoot_date
                ddhaim_chart {
                  data {
                    id
                  }
                }
              }
            }
          }
          cp_ceppro {
            data {
              id
            }
          }
        }
        id
      }
    }
  }
`;

export const DELETE_DDHAIM_PATIENT = gql`
mutation Mutation($deleteDdhaimPatientId: ID!) {
  deleteDdhaimPatient(id: $deleteDdhaimPatientId) {
    data {
      id
    }
  }
}
`;