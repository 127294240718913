import React from 'react';
import styled from 'styled-components';

import IconZoom from 'assets/icons/Zoom';
import { fontEN, palette } from 'constants/styles';

const SearchFormWrapper = styled.div`
  display: flex;
`;
const SearchText = styled.div`
  ${props => (props.theme === 'basic' && `
    width: 270px;
    height: 40px;
    background-color: ${props.active ? palette.navy[3] : palette.navy[0]};
    padding: 0 10px;
  `)};
  ${props => (props.theme === 'landmark' && `
    width: 190px;
    height: 30px;
    background-color: ${palette.semantic.gray[3]};
    padding: 0 5px;
  `)};
  ${props => (props.theme === 'analyze' && `
    width: 100%;
    height: 30px;
    background-color: ${palette.background.black};
    padding: 0 15px;
  `)}
  display: flex;
  align-items: center;
  border-radius: 5px;
  svg {
    margin-right: 5px;
  }
  input {
    width: 100%;
    ${props => (props.theme === 'basic' && `
      height: 40px;
      line-height: 40px;
    `)}
    ${props => (props.theme === 'landmark' && `
      height: 25px;
      line-height: 25px;
    `)}
    ${props => (props.theme === 'analyze' && `
      height: 30px;
      line-height: 15px;
      font-size: 11px;
    `)}
    color: ${palette.primary.white};
    background-color: transparent;
    font-family: ${fontEN};
  }
  input::placeholder {
    color: ${palette.grey[4]};
  }
`;

const SearchIconWrapper = styled.div`
  cursor:pointer;
`;

const SearchForm = ({ onChange, theme, onKeyPress, searchText }) => (   
  <SearchFormWrapper>
    <SearchText theme={theme} active={searchText !== ''}>
      <input
        type="text"
        onChange={onChange}
        onKeyDown={onKeyPress}
        placeholder={theme === 'basic' ? 'Search Name' : 'Search'}
      />
      <SearchIconWrapper
        onClick={onKeyPress}
      >
        <IconZoom
          width={(theme === 'landmark' || theme === 'analyze') && '11'}
          height={(theme === 'landmark' || theme === 'analyze') && '11'}
          color={searchText === '' ? palette.grey[0] : palette.primary.blue}
        />
      </SearchIconWrapper>
    </SearchText>
  </SearchFormWrapper>
);

export default SearchForm;