import { gql } from '@apollo/client';

// create data
// MUTATION
export const CREATE_CP_CEPPRO = gql`
  mutation CreateCpCeppro($data: CpCepproInput!) {
    createCpCeppro(data: $data) {
      data {
        id
      }
    }
  }
`;

// update data
export const UPDATE_CP_CEPPRO = gql`
  mutation UpdateCpCeppro($updateCpCepproId: ID!, $data: CpCepproInput!) {
    updateCpCeppro(id: $updateCpCepproId, data: $data) {
      data {
        attributes {
          createdAt
          drawing_result
          img_size
          infer_result
          modified_result
          profilogram_result
          ruler_info
          superimposition_data
          updatedAt
          vto_sto_config
          deletedAt
        }
      }
    }
  }
`;

// read data
export const GET_ALL_CP_CEPPROS = gql`
  query CpCeppros($filters: CpCepproFiltersInput) {
    cpCeppros(filters: $filters) {
      meta {
        pagination {
          total
        }
      }
      data {
        id
        attributes {
          infer_result
          modified_result
          profilogram_result
          ruler_info
          superimposition_data
          vto_sto_config
          drawing_result
          createdAt
          img_size
          ddhaim_chart {
            data {
              id
              attributes {
                ddhaim_chart_data {
                  data {
                    id
                    attributes {
                      image_type_cd
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// drawing data만 불러오기 위한 쿼리
export const GET_DRAWING_CEPPROS = gql`
query CpCeppros($filters: CpCepproFiltersInput) {
  cpCeppros(filters: $filters) {
    data {
      id
      attributes {
        drawing_result
      }
    }
  }
}
`;

export const GET_DOCTOR_UUID = gql`
query Attributes($filters: DdhaimUserFiltersInput) {
  ddhaimUsers(filters: $filters) {
    data {
      id
      attributes {
        uuid
        first_name
      }
    }
  }
}
`;