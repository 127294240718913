import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  createHttpLink,
} from '@apollo/client';
import { render } from 'react-dom';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import App from 'app';
import reportWebVitals from 'reportWebVitals';
import { Cookies } from 'react-cookie';
import { axiosRefreshToken } from 'modules/api/http';
import { pagePaths } from 'constants/paths';

const downCookies = new Cookies();
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APPOLO_DEV
});

const authLink = setContext((operation, { headers }) => {
  // gql direct 연결
  // const cookies = operation.variables.cookies;

  // api gql 연결
  const cookies = downCookies.get('token');

  return {
    headers: {
      ...headers,

      // gql direct 연결
      authorization: cookies ? `bearer ${cookies}` : ''
      // authorization: cookies ? `Bearer ${cookies[0].token}` : ''

      // api gql 연결
      // authorization: `bearer ${cookies}`
    }
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError }) => {
    try {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        ));
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        if (networkError.result.error.error_code === 'J005') {
          removeCookie();
          window.location.reload();
          return alert('Duplicate Login!');
        } else {
          return alert('Network Error');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
);

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      const refreshToken = downCookies.get('refreshToken');
      if (error && error.statusCode === 401 && refreshToken) {
        return new Promise((resolve, reject) => {
          axiosRefreshToken
            .post('/api/refresh-token', {
              refreshToken
            })
            .then((response) => {
              if (response.status === 200) {
                downCookies.remove('token', { path: '/' });
                downCookies.remove('refreshToken', { path: '/' });
                downCookies.set('token', response.data.data.token);
                downCookies.set(
                  'refreshToken',
                  response.data.data.refresh_token
                );
                
                operation.setContext({
                  headers: {
                    ...operation.getContext().headers,
                    Authorization: `Bearer ${response.data.data.token}`
                  }
                });
                resolve(true);
              } else {
                reject();
              }
            })
            .catch((error) => {
              console.log(error);
              
              // exit to home
              removeCookie();
              
              window.location.replace(pagePaths.home);
            });
        });
      }
      return !!error;
    },
  },
});

function removeCookie () {
  downCookies.remove('token', { path: '/' });
  downCookies.remove('refreshToken', { path: '/' });
  downCookies.remove('i', { path: '/' });
}
const appLink = from([errorLink, authLink.concat(retryLink).concat(httpLink)]);

const client = new ApolloClient({
  link: appLink,
  cache: new InMemoryCache(),
  // lazyquery 사용시 결과값이 변하지 않은경우에 추가
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only'
    }
  }
});

render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>, 
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
