import React, { useContext } from 'react';
import styled from 'styled-components';

import { ToastContext } from 'modules/context/ToastContext';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 170px);
`;

const TestToast = () => {
  const toastState = useContext(ToastContext);

  const handleOpenSuccessToast = () => {
    toastState.display(true);
    toastState.theme('basic');
    toastState.update({
      kind: 'success',
      title: 'Successfully Message',
      text: '토스트 메세지입니다.'
    });
  };

  const handleOpenWarningToast = () => {
    toastState.display(true);
    toastState.theme('basic');
    toastState.update({
      kind: 'warning',
      title: '비밀번호를 10회 잘못 입력하셔서 계정이 비활성화 되었습니다.',
      text: `<strong>test@ddhinc.net</strong> 으로 계정 활성 이메일이 발송되었습니다.
      메일 확인 후 다시 시도해 주세요.`
    });
  };

  const handleOpenErrorToast = () => {
    toastState.display(true);
    toastState.theme('basic');
    toastState.update({
      kind: 'error',
      title: '등록하신 이메일과 패스워드를 바르게 입력하여 주시기 바랍니다.',
      text: `비밀번호를 <strong>6회</strong> 잘못입력하셨습니다.
      비밀번호를 <strong>10</strong>회 잘못 입력하시면 계정이 비활성화 되고,
      이메일 인증을 다시 하셔야합니다.`
    });
  };

  return (
    <BodyWrapper>
      <button type="button" onClick={handleOpenSuccessToast}>Push Success Toast Message</button>
      <button type="button" onClick={handleOpenWarningToast}>Push Warning Toast Message</button>
      <button type="button" onClick={handleOpenErrorToast}>Push Error Toast Message</button>
    </BodyWrapper>
  );
};

export default TestToast;
