import React from 'react';

const Calendar = ({ width, height, fill }) => (
  <svg width={width || '20'} height={height || '20'} viewBox="0 0 20 20" fill={fill || 'none'} xmlns="http://www.w3.org/2000/svg">
    <path stroke="#DDDDDD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M15.8333 3.33337H4.16667C3.24619 3.33337 2.5 4.07957 2.5 5.00004V16.6667C2.5 17.5872 3.24619 18.3334 4.16667 18.3334H15.8333C16.7538 18.3334 17.5 17.5872 17.5 16.6667V5.00004C17.5 4.07957 16.7538 3.33337 15.8333 3.33337Z" />
    <path stroke="#DDDDDD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M2.5 8.33337H17.5" />
    <path stroke="#DDDDDD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M13.3333 1.66663V4.99996" />
    <path stroke="#DDDDDD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M6.66666 1.66663V4.99996" />
  </svg>
);

export default Calendar;