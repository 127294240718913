import Localization from 'i18n';
import { EMAIL_REGEX, PASSWORD_REGEX, NAME_REGEX, MOBILE_REGEX, PHONE_REGEX } from 'utils/regex';

// 필수 입력 필드만 빈 텍스트 체크한다
const formValidate = (name, formData, mode) => {
  switch (name) {
  case 'email': // 이메일 형식 체크
    if (!formData.email || formData.email.trim() === '') {
      return { email: (`${Localization.valid_email}`) };
    } else if (!EMAIL_REGEX.test(formData.email)) {
      return { email: (`${Localization.valid_wrong_email}`) };
    } else {
      return { email: '' };
    }
  case 'taxEmail': // 세금계산서 이메일 형식 체크
    if (!formData.taxEmail || formData.taxEmail.trim() === '') {
      return { taxEmail: (`${Localization.valid_tax_email}`) };
    } else if (!EMAIL_REGEX.test(formData.taxEmail)) {
      return { taxEmail: (`${Localization.valid_wrong_email}`) };
    } else {
      return { taxEmail: '' };
    }
  case 'name': // 영어 + 한글 + 공백
    if (!formData.name || formData.name.trim() === '') {
      return { name: (`${Localization.valid_user_name}`) };
    } else if (!NAME_REGEX.test(formData.name)) {
      return { name: (`${Localization.valid_alphabets_only}`) };
    } else {
      return { name: '' };
    }
  case 'currentPassword': // 대문자 + 소문자 + 숫자 + 특수문자 // 비밀번호 변경시 사용
    if (!formData.password || formData.password === '') {
      return { password: (`${Localization.valid_user_name}`) };
    } else {
      return { password: '' };
    } 
  case 'password': // 대문자 + 소문자 + 숫자 + 특수문자
    if (mode === 'signup') {
      if (!PASSWORD_REGEX.test(formData.password)) {
        return { password: (`${Localization.valid_text_combination}`) };
      } else {
        return { password: '' };
      }
    } else if (!formData.password || formData.password === '') {
      return { password: (`${Localization.valid_password}`) };
    } else {
      return { password: '' };
    }
  case 'confirmPassword': // 비밀번호 확인 체크
    if (formData.password && formData.confirmPassword && (formData.password !== formData.confirmPassword)) {
      return { confirmPassword: (`${Localization.valid_diff_password}`) };
    } else {
      return { confirmPassword: '' };
    }
  case 'mobileNumber': // 휴대전화 형식 체크
    if (!formData.mobileNumber || formData.mobileNumber === '') {
      return { mobileNumber: (`${Localization.input_mobile_number}`) };
    } else if (!MOBILE_REGEX.test(formData.mobileNumber)) {
      return { mobileNumber: `(${Localization.confirm_mobile_phone_number})` };
    } else {
      return { mobileNumber: '' };
    }
  case 'phoneNumber': // 회사 전화번호 형식 체크
    if (!formData.phoneNumber || formData.phoneNumber === '') {
      return { phoneNumber: (`${Localization.input_phone_number}`) };
    } else if (!PHONE_REGEX.test(formData.phoneNumber)) {
      return { phoneNumber: `${Localization.valid_error_phone_number}` };
    } else {
      return { phoneNumber: '' };
    }
  case 'faxNumber': // 팩스번호 형식 체크
    if (formData.faxNumber && !PHONE_REGEX.test(formData.faxNumber)) {
      return { faxNumber: (`${Localization.valid_error_fax_number}`) };
    } else {
      return { faxNumber: '' };
    }
  case 'taxNumber': // 사업자등록번호 체크
    if (!formData.taxNumber || formData.taxNumber === '') {
      return { taxNumber: (`${Localization.valid_tax_id}`) };
    } else if (!isBizNumber(formData.taxNumber)) {
      return { taxNumber: (`${Localization.valid_error_tax_id}`) };
    } else {
      return { taxNumber: '' };
    }
  case 'companyName': // 회사명
    if (!formData.companyName || formData.companyName.trim() === '') {
      return { companyName: (`${Localization.input_address1}`) };
    } else {
      return { companyName: '' };
    }
  case 'address2': // 상세주소
    if (!formData.address2 || formData.address2.trim() === '') {
      return { address2: (`${Localization.input_address2}`) };
    } else {
      return { address2: '' };
    }
  default:
    break;
  }
};

export default formValidate;

export const isEmail = (email) => {
  return EMAIL_REGEX.test(email);
};

export const isBizNumber = (number) => {
  const numberMap = number.replace(/-/gi, '').split('').map((d) => {
    return parseInt(d, 10);
  });

  if (numberMap.length === 10) {
    const keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    let chk = 0;

    keyArr.forEach((d, i) => {
      chk += d * numberMap[i];
    });

    chk += parseInt((keyArr[8] * numberMap[8]) / 10, 10);
    return Math.floor(numberMap[9]) === ((10 - (chk % 10)) % 10);
  }

  return false;
};
