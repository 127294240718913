import React, { useState, useContext, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Cookies } from 'react-cookie';
import { GlobalContext } from 'modules/context/GlobalContext';
import { ViewerContext } from 'modules/context/ViewerContext';
import { PopupContext } from 'modules/context/PopupContext';
import { BoardHeader } from 'components/board/BoardComponent';
import ListComponent from 'components/board/ListComponent';
import DefaultButton from 'components/button/DefaultButton';
import CreateBoard, {
  UploadImage,
  ImageItem,
  UploadForms,
  UploadFormTitle,
  UploadButton
} from 'components/board/CreateComponent';
import FormItem, { FormRow } from 'components/forms/FormItem';
import { pagePaths } from 'constants/paths';
import { GENDER_TYPE } from 'constants/types';
import BackButton from 'components/button/BackButton';
import formValidate from 'utils/formValidate';
import {
  CREATE_PATIENT_MUTATION,
  DELETE_DDHAIM_PATIENT,
  GET_ALL_PATIENT_LIST_OF_DOCTOR
} from 'modules/queries/patients';
import { CREATE_CHART_MUTATION } from 'modules/queries/chart';
import { CREATE_CP_CEPPRO } from 'modules/queries/viewer';
import Localization from 'i18n';
import {
  getDateFormat,
  getToday,
  handleDatePicker,
  cepUploadProcess,
  cephLandmarkFunc
} from 'utils/functions';
import Loading from 'components/common/Loading';
import { useDeleteChart, useDeletePatient } from 'modules/queries/commonQueries';

const WebSocketHost = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_WEBSOCKET_PROD
  : process.env.REACT_APP_WEBSOCKET_DEV;

const CreateContainer = ({ history, mode }) => {
  const popupState = useContext(PopupContext);
  const globalContext = useContext(GlobalContext);
  const viewerState = useContext(ViewerContext);
  const downCookies = new Cookies();
  const [userForm, setUserForm] = useState({
    name: '',
    birth: '',
    gender: '',
    shoot: new Date()
  });
  const [errorMessage, setErrorMessage] = useState({
    name: '',
    birth: '',
    shoot: ''
  });
  const [imgFile, setImgFile] = useState({
    cepparo: null,
    face: null
  });
  const [newChartId, setNewChartId] = useState(null);
  const [newPatientId, setNewPatientId] = useState(null);
  const [shootDate, setShootDate] = useState(getToday());
  const [createFlag, setCreateFlag] = useState(false);
  const cepproImgURL = useMemo(() => {
    if (imgFile.cepparo) return URL.createObjectURL(imgFile.cepparo);
  }, [imgFile.cepparo]); 
  const faceImgURL = useMemo(() => {
    if (imgFile.face) return URL.createObjectURL(imgFile.face);
  }, [imgFile.face]);

  const [getAllPatientListOfDoctor] = useLazyQuery(
    GET_ALL_PATIENT_LIST_OF_DOCTOR,
    {
      variables: {
        filters: {
          ddhaim_user: {
            id: {
              eq: globalContext.user.user_info.id
            }
          }
        }
      },
      onCompleted: () => {
      },
      onError: (error) => {
        console.log(error);
      }
    }
  );
  
  const [deletePatient] = useMutation(DELETE_DDHAIM_PATIENT);
  const [createChart] = useMutation(CREATE_CHART_MUTATION);
  const [createCpCeppro] = useMutation(CREATE_CP_CEPPRO);
  const [createPatient] = useMutation(CREATE_PATIENT_MUTATION);

  const handleUserFormChange = (e) => {
    setUserForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleValidate = (e) => {
    const returnData = formValidate(e.target.name, userForm);
    setErrorMessage((prev) => ({ ...prev, ...returnData }));
  };

  const handleGenderSelect = (e) => {
    const newObj = {};
    newObj[e.target.getAttribute('name')] = Number(
      e.target.getAttribute('value')
    );
    setUserForm({ ...userForm, ...newObj });
  };

  const handleFileChange = (name, file) => {
    setImgFile((prev) => ({ ...prev, [name]: file }));
  };

  const handleImgRemove = (type, e) => {
    e.preventDefault();
    e.stopPropagation();
    setImgFile((prev) => ({ ...prev, [type]: null }));
  };

  const handleSave = () => {
    globalContext.update({ isLoading: true });

    getAllPatientListOfDoctor({
      variables: {
        filters: {
          ddhaim_user: {
            id: {
              eq: globalContext.user.user_info.id
            }
          }
        }
      },
      onCompleted: (response) => {
        createPatient({
          variables: {
            data: {
              first_name: userForm.name,
              gender: userForm.gender === 1 ? Boolean(true) : Boolean(false),
              birth_info: userForm.birth && getDateFormat(userForm.birth),
              ddhaim_user: globalContext.user.user_info.id,
              patient_id: (
                response.ddhaimPatients.meta.pagination.total + 1
              ).toString()
            }
          },
          onCompleted: (response) => {
            setNewPatientId(response.createDdhaimPatient.data.id);
            const patientId = response.createDdhaimPatient.data.id;
            createChart({
              variables: {
                data: {
                  ddhaim_patient: patientId
                }
              },
              onCompleted:  (response) => {
                setNewChartId(response.createDdhaimChart.data.id);
                setCreateFlag(true);
              },
              onError: (error) => {
                console.log(error);
              }                                
            });
          },
          onError: (error) => {
            console.log(error);
            globalContext.update({ isLoading: false });
            popupState.update({
              display: true,
              title: Localization.xray_not_recognized,
              text: Localization.check_xray,
              textConfirm: Localization.confirm
            });
          }
        });
      },
      onError: (error) => {
        console.log(error);
      }
    });
  };

  const handleBack = () => {
    if (mode === 'modal') {
      globalContext.update({ modalMode: 'list' });
    } else {
      history.goBack();
    }
  };

  const xrayErrorPopup = () => {
    return popupState.update({
      display: true,
      title: Localization.xray_not_recognized,
      text: Localization.check_xray,
      textConfirm: Localization.confirm
    });
  };

  useEffect(() => {
    try {
      if (createFlag) {
        let socketInstance = null;
        const accessToken = downCookies.get('token');
        const message = {
          'type':'jwt',
          'token':accessToken
        };
      
        if (newChartId) {
          const cnt = imgFile.face !== null ? 2 : 1;
          let cntCheck = 0;
          socketInstance = new WebSocket(`${WebSocketHost}`);
          socketInstance.onopen = () => {
            socketInstance.send(JSON.stringify(message));
          };
          cepUploadProcess(imgFile.cepparo, imgFile.face, newChartId, shootDate)
            .then(() => {
            })
            .catch((error) => {
              console.log(error);
              globalContext.update({ isLoading: false });
              xrayErrorPopup();

              socketInstance.close();
              cntCheck = 0;
              useDeletePatient(deletePatient, newPatientId);
              useDeleteChart(newChartId);
              console.log(error);
            });

          socketInstance.onmessage = (msg) => {
            if (msg.data === 'DB thumbnail Saved') {
              cntCheck++;
              if (cnt === cntCheck) {
                cephLandmarkFunc(newChartId).then(([temp, response]) => {
                  if (temp.length > 0) {
                    createCpCeppro({
                      variables: {
                        data: {
                          ddhaim_chart: newChartId,
                          infer_result: { ...temp },
                          modified_result: { ...temp },
                          profilogram_result: viewerState.profilogramStandard,
                          vto_sto_config: {
                            data: { ...temp },
                            autoAlign: {},
                            point: [],
                            affectSvgTransform: {}
                          },
                          drawing_result: { coords: null, superimpositionCoords: null },
                          img_size: response.output.size
                        }
                      },
                      onCompleted: () => {
                        socketInstance.close();
                        cntCheck = 0;
                        globalContext.update({ isLoading: false });
                        history.push(pagePaths.list);
                        setCreateFlag(false);
                      },
                      onError: (error) => {
                        socketInstance.close();
                        cntCheck = 0;
                        console.log(error);
                        globalContext.update({ isLoading: false });
                      }
                    });
                  } else {
                    throw Error(response);
                  }
                }).catch((error) => {
                  console.log(error);
                  globalContext.update({ isLoading: false });
                  socketInstance.close();
                  cntCheck = 0;
                  xrayErrorPopup();
                  useDeletePatient(deletePatient, newPatientId);
                  useDeleteChart(newPatientId);
                });
              }
            }
          };
        }
      } 
    } catch (error) {
      console.log(error);
      globalContext.update({ isLoading: false });
      xrayErrorPopup();
    }
  }, [createFlag]);

  return (
    <>
      {globalContext.isLoading && <Loading />}
      <ListComponent mode={mode}>
        <BoardHeader
          theme="create"
          title={Localization.new_patient}
        >
          <BackButton theme="list" onClick={handleBack} />
        </BoardHeader>

        <CreateBoard>
          <UploadImage>
            <ImageItem
              title={Localization.cephalometric}
              icon="cepparo"
              name="cepparo"
              src={cepproImgURL}
              onClick={(e) => handleImgRemove('cepparo', e)}
              onChange={handleFileChange}
            >
              Drop your image here, or <strong>browse</strong>
              <span>File format: jpg / png</span>
            </ImageItem>
            <ImageItem
              title={Localization.left_face_image}
              icon="sideface"
              name="face"
              src={faceImgURL}
              onClick={(e) => handleImgRemove('face', e)}
              onChange={handleFileChange}
            >
              Drop your image here, or <strong>browse</strong>
              <span>File format: jpg / png</span>
            </ImageItem>
          </UploadImage>

          <UploadForms>
            <UploadFormTitle>{Localization.enter_patient_info}</UploadFormTitle>

            <FormRow>
              <FormItem
                label={Localization.name}
                type="text"
                name="name"
                maxLength="30"
                value={userForm.name}
                disabled={globalContext.modalMode === 'add'}
                onChange={handleUserFormChange}
                onBlur={handleValidate}
                errorMessage={errorMessage.name}
              />
              <FormItem
                label={Localization.patient_img_date}
                type="date"
                name="shoot"
                maxLength="30"
                value={userForm.shoot}
                disabled={globalContext.modalMode === 'add'}
                onDateChange={(date) => {
                  handleDatePicker(
                    'shoot',
                    date,
                    setUserForm,
                    setShootDate,
                    shootDate
                  );
                }}
                errorMessage={errorMessage.shoot}
              />
            </FormRow>

            <FormRow>
              <FormItem
                label={Localization.birthday}
                type="date"
                name="birth"
                maxLength="30"
                value={userForm.birth}
                disabled={globalContext.modalMode === 'add'}
                onDateChange={(date) => {
                  handleDatePicker(
                    'birth',
                    date,
                    setUserForm,
                    setShootDate,
                    shootDate
                  );
                }}
                errorMessage={errorMessage.birth}
              />
              <FormItem
                label={Localization.gender}
                name="gender"
                type="selectOne"
                disabled={globalContext.modalMode === 'add'}
                selectOneList={GENDER_TYPE}
                onSelectOne={handleGenderSelect}
                selectedOne={userForm.gender}
              />
            </FormRow>
          </UploadForms>

          <UploadButton>
            <DefaultButton
              size="large"
              disabled={
                userForm.name === ''
                || errorMessage.name !== ''
                || !userForm.birth
                || errorMessage.birth !== ''
                || userForm.gender === ''
                || imgFile.cepparo === null
              }
              onClick={handleSave}
            >
              {Localization.save}
            </DefaultButton>
          </UploadButton>
        </CreateBoard>
      </ListComponent>
    </>
  );
};

export default withRouter(CreateContainer);
