import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';

import DefaultButton from 'components/button/DefaultButton';
import CookieBanner from 'components/common/CookieBanner';
import { termsLink } from 'constants/links';
import Localization from 'i18n';
import { GlobalContext } from 'modules/context/GlobalContext';

const CookieBannerContainer = () => {
  const cookies = useCookies();
  const globalState = useContext(GlobalContext);
  const [getCookie, setCookie] = cookies;

  const handleClick = () => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 9999);
    setCookie('agreed', 'true', { path: '/', expires });
  };
  
  if (getCookie.agreed === 'true') return null;

  return (
    <CookieBanner
      title={Localization.agree_cookies}
      text={globalState.langIndex === 0
        ? `${Localization.agree_cookies_text1 + Localization.agree_cookies_text2}<a href=${termsLink.view.privacyPolicy.ko} target="_blank" rel="noopener noreferrer">${Localization.agree_cookies_text3}</a>${Localization.agree_cookies_text4}`
        : `${Localization.agree_cookies_text1 + Localization.agree_cookies_text2 + Localization.agree_cookies_text4}<a href=${termsLink.view.privacyPolicy.en} target="_blank" rel="noopener noreferrer">${Localization.agree_cookies_text3}</a>`}
    >
      <DefaultButton onClick={handleClick}>{Localization.allow}</DefaultButton>
    </CookieBanner>
  );
};

export default CookieBannerContainer;