export const termsLink = {
  download: {
    termsOfCondition: {
      ko: 'https://drive.google.com/uc?export=download&id=1U9EMYWqduIWkv3o6ivFOwc-MU_e7x-Q-',
      en: 'https://drive.google.com/uc?export=download&id=1ifelFEIEilm0srgiYXeDz_nxTPh4Gx_c',
    },
    privacyPolicy: {
      ko: 'https://drive.google.com/uc?export=download&id=1DKIjBmRkI_lOWHkO6rQ2GbeN4n_bm3WV',
      en: 'https://drive.google.com/uc?export=download&id=1v-Gct-BgmZCnKsgFyTAEPZLy7DnBzhwp',
    },
  },
  view: {
    termsOfCondition: {
      ko: 'https://drive.google.com/uc?export=view&id=1U9EMYWqduIWkv3o6ivFOwc-MU_e7x-Q-',
      en: 'https://drive.google.com/uc?export=view&id=1ifelFEIEilm0srgiYXeDz_nxTPh4Gx_c',
    },
    privacyPolicy: {
      ko: 'https://drive.google.com/uc?export=view&id=1DKIjBmRkI_lOWHkO6rQ2GbeN4n_bm3WV',
      en: 'https://drive.google.com/uc?export=view&id=1v-Gct-BgmZCnKsgFyTAEPZLy7DnBzhwp',
    },
  }
};

export const manualLink = {
  ko: 'https://drive.google.com/uc?export=download&id=12bSK8rMqP3fm14aBmnmad6aAvsT15a8T',
  en: 'https://drive.google.com/uc?export=download&id=1cnaU-6TXiiJfoWbZTf3rQlPsA4iO1Hlg'
};
