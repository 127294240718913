import React from 'react';

const Warning = ({ width, height, color }) => (
  <svg width={width || '16'} height={height || '16'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 1.45455C4.38505 1.45455 1.45455 4.38505 1.45455 8C1.45455 11.615 4.38505 14.5455 8 14.5455C11.615 14.5455 14.5455 11.615 14.5455 8C14.5455 4.38505 11.615 1.45455 8 1.45455ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" fill={color || '#DCA048'} />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.99973 7.27222C8.4014 7.27222 8.72701 7.59783 8.72701 7.99949V10.9086C8.72701 11.3102 8.4014 11.6359 7.99973 11.6359C7.59807 11.6359 7.27246 11.3102 7.27246 10.9086V7.99949C7.27246 7.59783 7.59807 7.27222 7.99973 7.27222Z" fill={color || '#DCA048'} />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.27246 5.09092C7.27246 4.68926 7.59807 4.36365 7.99973 4.36365H8.00734C8.40901 4.36365 8.73462 4.68926 8.73462 5.09092C8.73462 5.49258 8.40901 5.81819 8.00734 5.81819H7.99973C7.59807 5.81819 7.27246 5.49258 7.27246 5.09092Z" fill={color || '#DCA048'} />
  </svg>
);

export default Warning;