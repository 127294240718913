import { createContext, useState } from 'react';
import qs from 'qs';
import base64 from 'base-64';

import { apiPath } from 'constants/paths';
import { errorCheck } from 'utils/functions';
import { httpAPI } from './http';

export const UserContext = createContext();
export function useUserState() {
  const useStateInit = {
    id: '',
    useType: '',
    email: '',
    name: '',
    lang: '',
    token: ''
  };
  const [userState, setUserState] = useState(useStateInit);

  return {
    ...userState,
    setUserState
  };
}

const AuthAPI = (() => {
  function AuthAPIConstructor() {}

  AuthAPIConstructor.prototype = {
    signup(data) {
      data.password = base64.encode(data.password);
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.signup, { data: qs.stringify(data) })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
        // resolve(data)
      });
    },

    login(data) {
      data.password = base64.encode(data.password);
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.login, { data: qs.stringify(data) })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    loginCheck(cookies) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.loginCheck, {
          headers: {
            Authorization: `bearer ${cookies[0].token}`
          }
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    loginSuccess(user, remember, globalState, cookies) {
      const userForm = { ...user };
      const setCookie = cookies[1];
      globalState.update({ user: userForm });
      const token = user.token;
      const refreshToken = user.refresh_token;
      delete user.token;
      const infos = userForm;
      setCookie('token', token, { path: '/' });
      setCookie('refreshToken', refreshToken, { path: '/' });
      setCookie('i', JSON.stringify(infos), { path: '/' });
      if (remember) {
        const expires = new Date();
        expires.setDate(expires.getDate() + 7);
        setCookie('email', user.user_info.email, { path: '/', expires });
      }
    },

    logout(globalState, cookies) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.logout, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${cookies[0].token}`
          },
          cookies
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    keepGoing(data, cookies) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.keepGoing, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${cookies[0].token}`
          },
          data: qs.stringify(data),
          cookies
        })
          .then((response) => {
            resolve(response); 
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    checkEmail(data) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.checkEmail, { data: qs.stringify(data) })
          .then((response) => {
            // console.log(data);
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    checkTaxNumber(data) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.checkTaxNumber, { data: qs.stringify(data) })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    findEmail(data) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.findId, { data: qs.stringify(data) })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    // ! 베타용
    betaFindEmail(data) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.betaFindId, { data: qs.stringify(data) })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    findPassword(data) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.findPassword, { data: qs.stringify(data) })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    // ! 베타용
    betaFindPassword(data) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.betaFindPassword, { data: qs.stringify(data) })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    updatePassword(data) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.updatePassword, { data: qs.stringify(data) })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    updateUserInfo(data, cookies) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.updateUserInfo, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${cookies[0].token}`
          },
          data: qs.stringify(data),
          cookies
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    updateIdentification(data, cookies) {
      if (data.password) {
        data.password = base64.encode(data.password);
        data.user_password = base64.encode(data.user_password);
      }
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.updateIdentification, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${cookies[0].token}`
          },
          data: qs.stringify(data),
          cookies
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    authEmail(data) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.authEmail, { data: qs.stringify(data) })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    sendOtpSms(data) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.sendOtpSms, { data: qs.stringify(data) })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    smsSignup(data) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.smsSingup, { data: qs.stringify(data) })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    requestOtp(data, cookies) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.requestOtp, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${cookies[0].token}`
          },
          data: qs.stringify(data),
          cookies
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    smsMobile(data, cookies) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.smsMobile, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${cookies[0].token}`
          },
          data: qs.stringify(data),
          cookies
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    confirmIp(data) {
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.confirmIp, { data: qs.stringify(data) })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            errorCheck(error);
            reject(error);
          });
      });
    },

    recheckPw(data, cookies) {
      data.password = base64.encode(data.password);
      return new Promise((resolve, reject) => {
        httpAPI('', apiPath.recheckPw, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `bearer ${cookies[0].token}`
          },
          data: qs.stringify(data),
          cookies
        })
          .then((response) => {
            // console.log(response);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            errorCheck(error);
            reject(error);
          });
      });
    }
  };

  return AuthAPIConstructor;
})();

export const authAPI = new AuthAPI();
