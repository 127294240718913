const apiHost = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_REST_PROD
  : process.env.REACT_APP_REST_DEV;

export const apiPath = {
  refreshToken: `${apiHost}/api/refresh-token`,
  signup: `${apiHost}/public/sign-up`,
  checkTaxNumber: `${apiHost}/public/check-taxvalidation`,
  login: `${apiHost}/public/login`,
  logout: `${apiHost}/api/logout`,
  keepGoing: `${apiHost}/api/extend-pw`,
  authEmail: `${apiHost}/public/resend-authmail`,
  checkEmail: `${apiHost}/public/check-email-exist`,
  findId: `${apiHost}/public/find-id`,
  // ! 베타
  betaFindId: `${apiHost}/public/tfind-id`,
  findPassword: `${apiHost}/public/find-pw`,
  // ! 베타
  betaFindPassword: `${apiHost}/public/tfind-pw`,
  updatePassword: `${apiHost}/public/set-tempw`,
  confirmIp: `${apiHost}/public/change-ip-approval`,
  updateUserInfo: `${apiHost}/api/update-user/userinfo`,
  updateIdentification: `${apiHost}/api/update-user/identification`,
  sendOtpSms: `${apiHost}/public/send-otp-sms`,
  smsSingup: `${apiHost}/public/auth-sms`,
  requestOtp: `${apiHost}/api/send-otp-sms`,
  smsMobile: `${apiHost}/api/sms-update-mobile`,
  fileUpload: `${apiHost}/minio/upload`,
  fileDownload: `${apiHost}/minio/download`,
  chartDataRequest: `${apiHost}/minio/chart_data_request`,
  recheckPw: `${apiHost}/api/renter-password`,
  cephSuperimposition: `${apiHost}/ai_api/superimposition`,
  cephLandmark: `${apiHost}/ai/ceppro/ceph-landmark`,
  cephAutoalign: `${apiHost}/ai/ceppro/ceph-autoalign`,
  updateShootDate: `${apiHost}/api/update-shoot-date`,
  loginCheck: `${apiHost}/api/check`,
  licenseUpload: `${apiHost}/public/license-upload`,
  notice: `${apiHost}/public/notices`,
};

export const pagePaths = {
  home: '/',
  join: '/signup',
  findEmail: '/find-email',
  findPassword: '/find-password',
  list: '/list/:p',
  view: '/view/:id',
  create: '/create',
  viewer: '/viewer/:id',
  setting: '/setting',
  drawingPad: '/drawing-pad',
  membership: '/membership',
  myInfo: '/myinfo',
  resetEmail: '/reset-email',
  resetPassword: '/reset-password',
  resetPhone: '/reset-mobile'
};
