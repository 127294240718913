import { createContext, useState } from 'react';

export const DrawContext = createContext({});

const DrawInit = {
  drawingPad: false, // drawingPad use state
  drawingData: { coords: null, superimpositionCoords: null }, // drawing data
  padItem: 'Pen', // Pen, Measure, Angle, Screw
  drawPixel: 3, // pxiel
  colorState: '', // pen color
  measureColorState: '', // measure color
  angleColorState: '', // angle color
  drawOpacity: 1, // opacity
  coordinate: [], // 한 붓 그리기에서 나오는 좌표 저장
  coordinates: [], // 모든 그리기 좌표 저장
  drawHistory: [], // 그리기 히스토리 저장
  isDrawing: false,
  isDragging: false,
  screwIdx: -1, // screw index
  drawIdx: 0, // draw index
  historyNo: -1, // history index
  count: 0, // angle click count
  screw: [], // screw array
  ratio: 100, // drawingPad scale
  cephScale: 1, // ceph image scale
};

export function useDrawState() {
  const [drawState, setDrawState] = useState(DrawInit);

  const updateDrawState = (update) => {
    setDrawState(prev => ({ ...prev, ...update }));
  };

  const clearDrawState = () => {
    setDrawState(DrawInit);
  };

  return {
    ...drawState,
    update: updateDrawState,
    clear: clearDrawState,
  };
}