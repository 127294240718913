import React, { useContext, useEffect, useRef, useState } from 'react';
import HtmlParser from 'react-html-parser';
import styled from 'styled-components';

import { palette, zIndex } from 'constants/styles';
import { PopupContext } from 'modules/context/PopupContext';
import Delete from 'assets/icons/Delete';
import { useCookies } from 'react-cookie';
import Localization from 'i18n';

const Wrapper = styled.div`
  pointer-events: none;
  z-index: ${zIndex.modalPopup};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  `;

const PopupBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  pointer-events: fill;
  width: 400px;
  border-radius: 10px;
  background-color: ${palette.navy[5]};
`;

const PopupTitle = styled.div`
  line-height: 29px;
  width: 320px;
  color: ${palette.primary.white};
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  em {
    color: ${palette.semantic.orange};
  }
  overflow: hidden;
`;
const PopupText = styled.div`
  height: ${(props) => (props.event && props.imgUrl === '' ? 'fit-content' : '80px')};
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${palette.grey[4]};
  text-align: center;
  line-height: 150%;
  white-space: pre-line;
  word-wrap: break-word;
  overflow-y: scroll;  

  em {
    color: ${palette.primary.white};
    font-style: normal;
  }
`;

const PopupButtonWrap = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const PopupButton = styled.div`
  display: flex;
  button {
    width: 100%;
  }
  button + button { 
    margin-left: 13px;
  }
`;

const PopupImgWrap = styled.div`
  width: 100%;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupImg = styled.img`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-width: 400px; 
  max-height: 320px; 
  width: auto;
  height: auto;  
`;

const PopupContentWrap = styled.div`
  margin: 20px 0;
  padding: 0 40px;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
`;

const DeleteButton = styled.button`
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  background-color: ${palette.grey[5]};
  opacity: 0.8;
  margin-top: 10px;
  margin-left: 350px;
`;

const NotOpenButton = styled.button`
  margin-top: 5px;
  padding-left: 250px;
  color: ${palette.grey[4]};
  &:hover {
    text-decoration: underline;
    color: ${palette.primary.white};
  }
`;

const Popup = ({ title, text, children, imgUrl, event }) => {
  const cookies = useCookies();
  const [popupOpen, setPopupOpen] = useState(false);
  const popupRef = useRef();
  const popupState = useContext(PopupContext);
  const setCookie = cookies[1];
  
  const popupStateInit = () => {
    popupState.update({
      display : false,
      imgUrl: '',
      event: false,
      fnConfirm: () => popupState.clear()
    });
  };
  
  const NotOpen = () => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 1);
    setCookie('popup', 'true', { path: '/', expires });
    popupStateInit();
  };

  useEffect(() => {
    if (popupOpen) {
      popupRef.current.focus();
    }
  }, [popupOpen]);

  useEffect(() => {
    try {
      if (title !== undefined && text !== undefined && children !== undefined) {
        setPopupOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  }, [title, text, children]);

  return (
    <Wrapper
      ref={popupRef} popupOpen={popupOpen} onKeyDown={(e) => {
        if (e.keyCode === 27) {
          setPopupOpen(false);
          popupStateInit();
        }
      }}
      tabIndex="-1">
      <PopupBox event={event}>
        {event && (
          <DeleteButton onClick={() => { 
            setPopupOpen(false); 
            popupStateInit();
          }}> <Delete color="white" width={20} height={20} /> 
          </DeleteButton>
        )}
        {imgUrl && (
          <PopupImgWrap> 
            <PopupImg src={imgUrl} alt="" /> 
          </PopupImgWrap>
        )}
        <PopupContentWrap>
          {title 
        && (
          <PopupTitle>
            {title}
          </PopupTitle>
        )}
          {text
        && (
          <PopupText event={event} imgUrl={imgUrl}>
            {HtmlParser(text)}
          </PopupText>
        )}
          <PopupButtonWrap>
            <PopupButton>
              {children}
            </PopupButton>
          </PopupButtonWrap>
        </PopupContentWrap>
      </PopupBox>
      {event && (
        <NotOpenButton onClick={() => {
          NotOpen();
          setPopupOpen(false);
        }}>{Localization.not_open_today}
        </NotOpenButton>
      )}
    </Wrapper>
  );
};

export default Popup;