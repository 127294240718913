import React from 'react';
import styled from 'styled-components';

import { fontEN, palette } from 'constants/styles';
import IconPrev from 'assets/icons/PageArrowPrev';
import IconPrevLarge from 'assets/icons/PageArrowPrevLarge';
import IconNext from 'assets/icons/PageArrowNext';
import IconNextLarge from 'assets/icons/PageArrowNextLarge';

const BoardFooterWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  padding: 0 10px;
  border-radius: 0 0 8px 8px;
  background-color: ${palette.navy[1]};
  color: ${palette.grey[1]};
`;
const TotalCount = styled.div`
  position: absolute;
  left: 20px;
  font-size: 12px;
  font-family: ${fontEN};
`;
const PageNumButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  line-height: 16px;
  color: ${palette.grey[1]};
  font-size: 13px;
  font-family: ${fontEN};
  :hover:not(:disabled) {
    color: ${palette.primary.blue};
  }
  ${props => (props.active && `
    color: ${palette.primary.white};
    font-weight: 700;
  `)}
`;
const Pagenation = styled.div`
  display: flex;
  height: 16px;
  button + button {
    margin-left: 12px;
  }
`;
const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 16px;

  :hover:not(:disabled) svg path {
    fill: ${palette.primary.blue};
  }
  :disabled svg path {
    fill: ${palette.grey[5]};
  }
`;

const BoardFooter = ({ pageIndex, unit, page, total, handleClickPageNumber, handleClickPageButton }) => {
  const pageLength = pageIndex.length;
  
  // pageIndex 5 단위로 나눠서 2차원배열화
  const newPageIndex = [];
  const indexDivide = 5;
  const pageSetting = (value) => {
    const length = pageLength;
    let array = [];

    for (let i = 0; i < length; i++) {
      if (i === length - 1) {
        array.push(length); 
        newPageIndex.push(array);
        break;
      } else if (value[i] % indexDivide === 0) {
        array.push(value[i]);
        newPageIndex.push(array);
        array = [];
      } else if (i !== length - 1 && value[i] % indexDivide !== 0) {
        array.push(value[i]);
      }      
    }
    return newPageIndex;
  };
  
  pageSetting(pageIndex);

  // 어떤 Index 사용할건지
  const index = (value) => {
    if (value === 0) return 0;
    else if (value % indexDivide === 0) return value / indexDivide;
    return Math.floor(value / 5);
  };
  
  return (
    <BoardFooterWrapper>
      <TotalCount>Total: {total}</TotalCount>
      <Pagenation>
        {/* 이전 5페이지 이동 버튼 */}
        <ArrowButton
          type="button" disabled={newPageIndex[index(page) - 1] === undefined} 
          onClick={() => handleClickPageButton(-indexDivide)}><IconPrevLarge />
        </ArrowButton>
        {/* 이전 페이지 이동 버튼 */}
        <ArrowButton
          type="button" disabled={page < 1} 
          onClick={() => handleClickPageButton(-1)}>
          <IconPrev />
        </ArrowButton>
        {/* 페이지 넘버별 직접 이동 */}
        {(newPageIndex.length > 0)
        && (newPageIndex[index(page)] !== undefined)
        && newPageIndex[index(page)].map((d, i) => {
          return (
            <PageNumButton
              key={i} type="button"
              active={page === Math.floor(page / pageLength) * pageLength + d - 1}
              disabled={page === Math.floor(page / pageLength) * pageLength + d - 1}
              onClick={() => handleClickPageNumber(Math.floor(page / unit) + d - 1)}
            > 
              {/* 0에서 9사이의 수라면 0을 붙여줌 */}
              {
                d <= 9
                  ? `0${(Math.floor(page / pageLength) * pageLength + d).toString()}`
                  : (Math.floor(page / pageLength) * pageLength + d).toString()
              }
            </PageNumButton>
          );
        })}
        {/* 다음 페이지 이동 버튼 */}
        <ArrowButton
          type="button" disabled={page + 1 === pageLength}
          onClick={() => handleClickPageButton(1)}><IconNext />
        </ArrowButton>
        {/* 다음 5페이지 이동 버튼 */}
        <ArrowButton
          type="button" disabled={newPageIndex[index(page) + 1] === undefined} 
          onClick={() => handleClickPageButton(indexDivide)}><IconNextLarge />
        </ArrowButton>
      </Pagenation>
    </BoardFooterWrapper>
  );
};

export default BoardFooter;