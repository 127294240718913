import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Header, {
  SelectLangGroup,
  HeaderLogin
} from 'components/common/Header';
import { GlobalContext } from 'modules/context/GlobalContext';
import { pagePaths } from 'constants/paths';
import { authAPI } from 'modules/api/auth';
import Localization, { langsSelectList } from 'i18n';

const HeaderContainer = ({ history, location }) => {
  const cookies = useCookies();
  const globalContext = useContext(GlobalContext);
  const [selectedLangIndex, setSelectedLangIndex] = useState();
  const [preLocation, setPreLocation] = useState();
  
  const handleLangChange = (index) => {
    setSelectedLangIndex(index);
    globalContext.update({ langIndex: index });
    const langCode = Object.keys(langsSelectList)[index];
    Localization.setLanguage(langCode);
    window.localStorage.setItem('lang', langCode);
    document.querySelector('html').setAttribute('lang', langCode);
  };

  const handleMovePage = (path) => {
    if (history.location.pathname !== path) {
      history.push(path);
    }
  };

  const handleLogout = () => {
    authAPI
      .logout(globalContext, cookies)
      .then(() => {
        history.push(pagePaths.home);
      })
      .finally(() => {
        history.push(pagePaths.home);
      });

    const removeCookie = cookies[2];
    removeCookie('token');
    removeCookie('i');
    removeCookie('refreshToken');

    globalContext.update({ user: {} });
    history.push(pagePaths.home);
  };

  const handleBack = () => {
    const locationHistory = JSON.parse(
      sessionStorage.getItem('locationHistory')
    );
    // back 누르면 pop 진행
    const pop = locationHistory.pop();
    // pop 된거랑 같은 주소면 한번 더 pop 진행
    if (pop === locationHistory.slice(-1)[0]) locationHistory.pop();
    if (locationHistory.length === 0) {
      history.push(pagePaths.list.replace(':p', 0));
      return;
    }

    // 히스토리가 list 면 list Page로 이동 / 아니면 viewer로 이동
    // viewer에서는 히스토리가 viewer 여도 리스트로 가야함
    if (locationHistory.slice(-1)[0].includes('list')) {
      const page = locationHistory.slice(-1)[0].split('/')[2];
      history.push(pagePaths.list.replace(':p', page));
    } else if (locationHistory.slice(-1)[0].includes('viewer')) {
      if (location.pathname.includes('viewer')) {
        history.push(pagePaths.list.replace(':p', 0));
      } else {
        const id = locationHistory.slice(-1)[0].split('/')[2];
        history.push(pagePaths.viewer.replace(':id', id));
      }
    }
  };

  useEffect(() => {
    try {
      setPreLocation(location.pathname.split('/')[1]);
    } catch (error) {
      console.log(error);
    }
  }, [location.pathname]);

  useEffect(() => {
    try {
      setSelectedLangIndex(globalContext.langIndex);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      // location 바뀌면
      const arr = [];
      // history가 있는지 확인. 있다면?
      if (sessionStorage.getItem('locationHistory')) {
        const locationHistory = JSON.parse(
          sessionStorage.getItem('locationHistory')
        );
        // 20개 이상일 때 처음 10개 지우기
        if (locationHistory.length > 20) locationHistory.splice(0, 10);
        // 이전 history 주소가 myinfo, setting, reset --- 일 땐 지워주기
        if (
          locationHistory.slice(-1)[0] === '/setting'
          || locationHistory.slice(-1)[0] === '/myinfo'
          || locationHistory.slice(-1)[0].includes('reset')
        )
          locationHistory.pop();
        // 기존의 history 추가
        arr.push(...locationHistory);
        // 새로 추가
        arr.push(location.pathname);
        // session에 추가
        sessionStorage.setItem('locationHistory', JSON.stringify(arr));
      } else {
        // 없다면 session storage 새로 저장
        arr.push(location.pathname);
        sessionStorage.setItem('locationHistory', JSON.stringify(arr));
      }
    } catch (error) {
      console.log(error);
    }
  }, [location.pathname]);

  return (
    <Header
      logoLink={cookies[0].token ? pagePaths.list : pagePaths.home}
      onClick={handleBack}
      preLocation={preLocation}
    >
      <SelectLangGroup
        theme={location.pathname.split('/')[1] === 'viewer' && 'viewer'}
        langs={langsSelectList}
        selectedIndex={selectedLangIndex}
        onClick={handleLangChange}
      />
      {cookies[0].token && globalContext.user && (
        <HeaderLogin
          email={globalContext.user && globalContext.user.user_info.email}
          name={globalContext.user && globalContext.user.user_info.first_name}
          theme={location.pathname.split('/')[1] === 'viewer' && 'viewer'}
          onSetting={() => handleMovePage(pagePaths.setting)}
          onLogout={handleLogout}
        />
      )}
    </Header>
  );
};

export default withRouter(HeaderContainer);
