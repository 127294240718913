import React from 'react';
import styled from 'styled-components';

import { fontEN, palette } from 'constants/styles';
import IconArrowLeft from 'assets/icons/ArrowLeft';
import Localization from 'i18n';

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  height: 20px;
  font-family: ${fontEN};
  font-size: 12px;
  text-transform: uppercase;
  color: ${palette.grey[0]};

  svg {
    margin-right: 5px;
    path {
      fill: ${palette.grey[0]};
    }
  }

  span {
    margin-left: 5px;
    color: ${palette.primary.blue};
    font-family: ${fontEN};
  }
  :hover:not(:disabled) {
    color: ${palette.primary.white};
    span {
      color: ${palette.hover.blue};
    }
    svg path {
      fill: ${palette.primary.white};
    }
  }
`;
const BackButton = (props) => (
  <ButtonWrapper type="button" {...props} className="btn-back">
    <IconArrowLeft />
    Back 
    {props.theme === 'list' && <span>{Localization.patient_list}</span>}
    {props.theme === 'back' && <span>{Localization.go_back}</span>}
  </ButtonWrapper>
);

export default BackButton;