import React from 'react';
import styled from 'styled-components';

import { palette, fontEN, size } from 'constants/styles';
import bg from 'assets/bg-log-in.svg';

const LoginFromLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${window.innerHeight > size.minHeight ? window.innerHeight - size.header - size.footer : size.minHeight - size.header - size.footer}px;
`;

const LoginFormWrapper = styled.div`
  width: 527px;
  height: 243px;
  padding: 41px 50px 44px 47px;
  background: url(${bg}) no-repeat;
`;

const Title = styled.div`
  height: 30px;
  color: ${palette.primary.blue};
  font-family: ${fontEN};
  font-size: 20px;
  font-weight: 800;
`;
export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;
export const InputForm = styled.div`
  width: 100%;
`;
export const InputItem = styled.div`
  display: flex;
  height: 24px;
  & + & {
    margin-top: 18px;
  }
`;
export const Label = styled.label`
  flex-shrink: 0;
  width: 82px;
  color: ${palette.grey['0']};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
`;
export const Input = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${props => (props.focus ? palette.primary.white : palette.grey[4])};
  input {
    width: 100%;
    height: 19px;
    font-family: ${fontEN};
    background-color: transparent;
    color: ${palette.primary.white};
    font-size: 13px;
    text-indent: 4px;
    ::placeholder {
      color: ${palette.grey[1]};
    }
  }
`;
export const ShowButton = styled.button`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
`;
export const InputButton = styled.div`
  flex-shrink: 0;
  width: 63px;
  margin-left: 30px;
`;
export const FormErrorMessage = styled.div`
  height: 17px;
  color: ${palette.semantic.orange};
  font-size: 12px;
  margin-top: 4px;
  ${props => (props.theme === 'login' && (`
    margin-left: 82px;
  `))}
`;
export const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 9px 0 0 82px;
`;
export const FooterLinks = styled.div`
  color: ${palette.grey['4']};
  font-size: 11px;
  a + a {
    margin-left: 5px;
    &:before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 11px;
      margin-top: -2px;
      margin-right: 5px;
      background-color: ${palette.grey['4']};
      vertical-align: middle;
    }
  }
  a:hover {
    text-decoration: underline;
    color: ${palette.primary.white};
  }
`;

export const FormRemember = styled.div`
  color: ${props => (props.checked ? palette.primary.white : palette.grey['4'])};
  font-size: 11px;
  :hover {
    color: ${palette.primary.white};
  }
`;

const LoginLayout = (props) => (
  <LoginFromLayout>
    <LoginFormWrapper>
      <Title>Log In</Title>
      {props.children}
    </LoginFormWrapper>
  </LoginFromLayout>
);

export default LoginLayout;
