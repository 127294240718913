import { createContext, useState } from 'react';

export const PopupContext = createContext({});

const initState = {
  display: false,
  title: '',
  text: '',
  textConfirm: '',
  textCancel: '',
  fnConfirm: null,
  fnCancel: null,
  imgUrl: null,
  event: false,
};

export function usePopupState() {
  const [state, setState] = useState(initState);

  const updateState = (update) => {
    setState(prev => ({ ...prev, ...update }));
  };

  return {
    ...state,
    update: updateState,
    clear: () => setState(initState)
  };
}