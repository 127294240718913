import React from 'react';

const Download = ({ width, height, color }) => (
  <svg width={width || '13'} height={height || '19'} viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={color || '#F1F1F1'} d="M2.96125 14.5C3.27125 14.5 3.53958 14.5517 3.76625 14.655C3.99625 14.7583 4.17292 14.905 4.29625 15.095C4.41958 15.285 4.48125 15.51 4.48125 15.77C4.48125 16.0267 4.41958 16.2517 4.29625 16.445C4.17292 16.635 3.99625 16.7817 3.76625 16.885C3.53958 16.985 3.27125 17.035 2.96125 17.035H2.25625V18H1.44625V14.5H2.96125ZM2.91625 16.375C3.15958 16.375 3.34458 16.3233 3.47125 16.22C3.59792 16.1133 3.66125 15.9633 3.66125 15.77C3.66125 15.5733 3.59792 15.4233 3.47125 15.32C3.34458 15.2133 3.15958 15.16 2.91625 15.16H2.25625V16.375H2.91625ZM5.05953 14.5H6.64953C7.02953 14.5 7.36453 14.5733 7.65453 14.72C7.94786 14.8633 8.17453 15.0667 8.33453 15.33C8.49786 15.5933 8.57953 15.9 8.57953 16.25C8.57953 16.6 8.49786 16.9067 8.33453 17.17C8.17453 17.4333 7.94786 17.6383 7.65453 17.785C7.36453 17.9283 7.02953 18 6.64953 18H5.05953V14.5ZM6.60953 17.335C6.95953 17.335 7.23786 17.2383 7.44453 17.045C7.65453 16.8483 7.75953 16.5833 7.75953 16.25C7.75953 15.9167 7.65453 15.6533 7.44453 15.46C7.23786 15.2633 6.95953 15.165 6.60953 15.165H5.86953V17.335H6.60953ZM10.0004 15.15V16.075H11.6204V16.725H10.0004V18H9.19039V14.5H11.8354V15.15H10.0004Z" />
    <path stroke={color || '#F1F1F1'} strokeLinecap="round" strokeLinejoin="round" d="M11.375 8.125V10.2917C11.375 10.579 11.2609 10.8545 11.0577 11.0577C10.8545 11.2609 10.579 11.375 10.2917 11.375H2.70833C2.42102 11.375 2.14547 11.2609 1.9423 11.0577C1.73914 10.8545 1.625 10.579 1.625 10.2917V8.125" />
    <path stroke={color || '#F1F1F1'} strokeLinecap="round" strokeLinejoin="round" d="M3.79169 5.41667L6.50002 8.125L9.20835 5.41667" />
    <path stroke={color || '#F1F1F1'} strokeLinecap="round" strokeLinejoin="round" d="M6.5 8.125V1.625" />
  </svg>
);

export default Download;