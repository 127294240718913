import { gql } from '@apollo/client';

export const GET_ALL_CP_USER_CONFIGS = gql`
  query CpUserConfigs($filters: CpUserConfigFiltersInput) {
    cpUserConfigs(filters: $filters) {
      data {
        id
        attributes {
          auto_measurement_NY
          auto_zoom_NY
          cep_mode
          user_analysis_manage
          updatedAt
          unique_id
          soft_tissue_size
          soft_tissue_outline_NY
          soft_tissue_color
          plane_NY
          plane_list
          landmark_list
          landmark_NY
          hard_tissue_size
          hard_tissue_outline_NY
          hard_tissue_color
          cephalo_image_NY
          createdAt
          default_analysis_method_NY
          default_ppm
          deletedAt
          drawing_config
          guideline_NY
        }
      }
    }
  }
`;

export const UPDATE_CP_USER_CONFIG = gql`
  mutation UpdateCpUserConfig(
    $updateCpUserConfigId: ID!
    $data: CpUserConfigInput!
  ) {
    updateCpUserConfig(id: $updateCpUserConfigId, data: $data) {
      data {
        id
        attributes {
          hard_tissue_color
          hard_tissue_size
          hard_tissue_outline_NY
          soft_tissue_color
          soft_tissue_size
          soft_tissue_outline_NY
          default_ppm
          cephalo_image_NY
          landmark_NY
          landmark_list
          guideline_NY
          default_analysis_method_NY
          auto_zoom_NY
          auto_measurement_NY
          plane_NY
          plane_list
          drawing_config
          cep_mode
          user_analysis_manage
          deletedAt
          unique_id
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const UPDATE_DDHAIM_USER = gql`
  mutation Mutation($updateDdhaimUserId: ID!, $data: DdhaimUserInput!) {
    updateDdhaimUser(id: $updateDdhaimUserId, data: $data) {
      data {
        attributes {
          user_permission
        }
      }
    }
  }
`;

export const GET_USER_PERMISSION = gql`
query Query($filters: DdhaimUserFiltersInput) {
  ddhaimUsers(filters: $filters) {
    data {
      attributes {
        user_permission
      }
    }
  }
}
`;