import React from 'react';
import styled from 'styled-components';
import { size } from 'constants/styles';

const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: ${size.minHeight}px;
    min-width: ${size.minWidth}px;
    position: relative;
`;

const AppLayout = ({ children }) => (
  <AppWrapper>
    {children}
  </AppWrapper>
);

export default AppLayout;