import { viewerAPI } from 'modules/api/viewerAPI';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React from 'react';
import Localization from 'i18n';

export const fetchImage = async (value) => {
  const response = await fetch(value);
  const blobData = await response.blob();
  return URL.createObjectURL(blobData);
};

export const CepTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right" />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    border: '1px solid #dadde9',
  },
}));

export const errorCheck = (e) => {
  if (e === 404) {
    alert(Localization.error404);
  } else if (e === 500) {
    alert(Localization.error500);
  } else if (e === 'ECONNABORTED') { // timeout
    alert(Localization.time_out);
  }
};

export const handleMouseWheel = (e, deltaPosition, zoom, setDeltaPosition, setZoom, state) => {
  const xs = (e.nativeEvent.layerX - (state !== 'Superimposition' ? deltaPosition.x : zoom.x) * (zoom.scale)) / (zoom.scale);
  const ys = (e.nativeEvent.layerY - (state !== 'Superimposition' ? deltaPosition.y : zoom.y) * (zoom.scale)) / (zoom.scale);
  const delta = e.nativeEvent.wheelDelta
    ? e.nativeEvent.wheelDelta
    : -e.deltaY;

  let ratio = zoom.scale;

  const zoomRatio = 1.03;

  ratio = delta > 0 ? (ratio *= zoomRatio) : (ratio /= zoomRatio);
  if (ratio >= 4) ratio = 4;
  if (zoom.scale <= 1 && ratio <= 0.5) ratio = 0.5;
  const translateX = e.nativeEvent.layerX - xs * ratio;
  const translateY = e.nativeEvent.layerY - ys * ratio;

  if (state !== 'Superimposition') {
    setDeltaPosition({
      x: translateX / ratio,
      y: translateY / ratio
    });
      
    setZoom({ ...zoom, scale: ratio });
  } else {
    setZoom({
      x: translateX / ratio,
      y: translateY / ratio,
      scale: ratio
    });
  }
};

export function getRotateDegreeCoord(degree, point, origin) {
  const translatedX = point.x - origin.x;
  const translatedY = point.y - origin.y;

  const radians = -1 * degree * (Math.PI / 180);
  const rotatedX = translatedX * Math.cos(radians) - translatedY * Math.sin(radians);
  const rotatedY = translatedX * Math.sin(radians) + translatedY * Math.cos(radians);

  const finalX = rotatedX + origin.x;
  const finalY = rotatedY + origin.y;

  return { x: finalX, y: finalY };
}

export function rotate(point, angle) {
  const [x, y] = point;
  const rotatedX = x * Math.cos(angle) - y * Math.sin(angle);
  const rotatedY = x * Math.sin(angle) + y * Math.cos(angle);
  return [rotatedX, rotatedY];
}

export function getSlope(p1, p2) {
  const firstX = p1.x;
  const firstY = p1.y;
  const secondX = p2.x;
  const secondY = p2.y;

  const slope = (firstY - secondY) / (firstX - secondX);

  return slope;
}

export function calculateY(x, slope, yIntercept) {
  return slope * x + yIntercept;
}

export function calculateX(y, slope, yIntercept) {
  return (y - yIntercept) / slope;
}

export function getFHPerpendicularCoord(viewerState, points) {
  const porionX = viewerState.data[1].x;
  const porionY = viewerState.data[1].y;
  const orbitaleX = viewerState.data[5].x;
  const orbitaleY = viewerState.data[5].y;

  const FHslope = (porionY - orbitaleY) / (porionX - orbitaleX);

  const firstLinearIntercept = points[0][1] - FHslope * points[0][0]; 
  const secondLinearIntercept = points[1][1] + (1 / FHslope) * points[1][0]; 

  const perpendicularX = (secondLinearIntercept - firstLinearIntercept) / (FHslope + (1 / FHslope));
  const perpendicularY = FHslope * perpendicularX + firstLinearIntercept;

  return [perpendicularX, perpendicularY];
}

export function getLength(p1, p2) {
  // dist
  return Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
}

export function getAngle(p1, p2) {
  // ang
  return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
}

export function midPoint(p1, p2) {
  // mid
  const cx = (p1.x + p2.x) / 2;
  const cy = (p1.y + p2.y) / 2;
  return { x: cx, y: cy };
}

export function horizontalDistance(p1, p2) {
  // hdist
  return Math.abs(p1.x - p2.x);
}

export function verticalDistance(p1, p2) {
  // vidst
  return Math.abs(p1.y - p2.y);
}

export function pointToLineDistance(p1, p2, p3, direction) {
  // if direction ppdist else pddist
  let midres1;
  if (direction)
    midres1 = p1.x * p2.y
      + p2.x * p3.y
      + p3.x * p1.y
      - p2.x * p1.y
      - p3.x * p2.y
      - p1.x * p3.y;
  else
    midres1 = Math.abs(
      p1.x * p2.y
        + p2.x * p3.y
        + p3.x * p1.y
        - p2.x * p1.y
        - p3.x * p2.y
        - p1.x * p3.y
    );
  const midres2 = Math.sqrt((p2.x - p3.x) ** 2 + (p2.y - p3.y) ** 2);
  return midres1 / midres2;
}

export function parallelPointToLineDistance(p1, p2, p3, p4) {
  // prdist
  const midres3 = (p1.x - p2.x) * (p3.x - p4.x) + (p1.y - p2.y) * (p3.y - p4.y);
  const midres4 = Math.sqrt((p3.x - p4.x) ** 2 + (p3.y - p4.y) ** 2);
  return midres3 / midres4;
}

export function getThreePointsAngle(p1, p2, p3) {
  // ang1
  const angle12 = Math.atan2(
    verticalDistance(p1, p2),
    horizontalDistance(p1, p2)
  );
  const angle32 = Math.atan2(
    verticalDistance(p3, p2),
    horizontalDistance(p3, p2)
  );
  return ((angle12 - angle32) * 180) / Math.PI;
}

export function getTwoLinesAngle(p1, p2, p3, p4) {
  // ang2
  const angle12 = Math.atan2(
    verticalDistance(p1, p2),
    horizontalDistance(p1, p2)
  );
  const angle34 = Math.atan2(
    verticalDistance(p3, p4),
    horizontalDistance(p3, p4)
  );
  return angle12 - angle34;
}

export const lineSlope = (p1, p2) => {
  return (p2.y - p1.y) / (p2.x - p1.x);
};

export const extensionLine = (p1, p2X, slope, maxY) => {
  const y = p1.y - slope * p1.x;
  let valueY1 = p1.y - slope * p1.x;
  let valueY2 = slope * p2X + valueY1;
  let valueX1 = 0;
  let valueX2 = p2X;

  if (valueY1 > maxY) {
    valueX1 = (maxY - y) / slope;
    valueY1 = maxY;
  } 
  
  if (valueY2 > maxY) {
    valueX2 = (maxY - y) / slope;
    valueY2 = maxY;
  }
  
  if (valueY1 < 0) {
    valueX1 = -y / slope;
    valueY1 = 0;
  } 
  
  if (valueY2 < 0) {
    valueX2 = -y / slope;
    valueY2 = 0;
  } 

  return [valueX1, valueY1, valueX2, valueY2];
};

export function getThreePointsVericalPoint(p1, p2, p3) {
  const slope = lineSlope(p2, p3);
  const valueY1 = p2.y - slope * p2.x;
  const valueY2 = p1.y + p1.x / slope;
  const newPointX = (valueY2 - valueY1) / (slope + 1 / slope);
  const newPointY = (-1 / slope) * newPointX + valueY2;

  return [newPointX, newPointY];
}

export const getDateFormat = (date) => {
  if (!date) return date;
  if (typeof date === 'string') return date.split('T')[0];
  const y = date.getFullYear();
  const m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  return `${y}-${m}-${d}`;
};

export const shootDateInput = (shootDate) => {
  const YY = shootDate.split('-')[0];
  const MM = shootDate.split('-')[1].length <= 1
    ? `0${shootDate.split('-')[1]}`
    : `${shootDate.split('-')[1]}`;

  const DD = shootDate.split('-')[2].length <= 1
    ? `0${shootDate.split('-')[2]}`
    : `${shootDate.split('-')[2]}`;

  const result = `${YY}-${MM}-${DD}`;

  return result;
};

export const getToday = () => {
  const dateYear = new Date().getFullYear();
  const dateMonth = new Date().getMonth() + 1;
  const dateDay = new Date().getDate();

  const Today = `${dateYear}-${dateMonth}-${dateDay}`;

  return Today;
};

export const handleDatePicker = (
  name,
  value,
  setUserForm,
  setShootDate,
  shootDate
) => {
  if (name === 'birth') {
    const obj = {};
    obj[name] = value;
    setUserForm((prev) => ({ ...prev, ...obj }));
  } else if (name === 'shoot') {
    if (value) {
      setShootDate(getDateFormat(value));
      const obj = {};
      obj[name] = value;
      setUserForm((prev) => ({ ...prev, ...obj }));
    } else if (value !== undefined) {
      const obj = {};
      obj[name] = shootDate;
      setUserForm((prev) => ({ ...prev, ...obj }));
    }
  } else if (name === 'changeShoot') {
    setShootDate(getDateFormat(value));
    const obj = {};
    obj[name] = value;
  }
};

export const cepFileUpload = (chartId, cepImg, faceImg, shootDate) => {

  const uploadList = [];
  if (cepImg) {
    const uploadCeph = new Promise((resolve, reject) => {
      viewerAPI
        .fileUpload(
          {
            chart_id: chartId,
            image_type_cd: '00700',
            original_name: `${chartId}_00700.jpg`,
            shoot_date: shootDateInput(shootDate)
          },
          cepImg
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
    uploadList.push(uploadCeph);
  }

  if (faceImg) {
    const uploadFace = new Promise((resolve, reject) => {
      viewerAPI
        .fileUpload(
          {
            chart_id: chartId,
            image_type_cd: '00710',
            original_name: `${chartId}_00710.jpg`,
            shoot_date: shootDateInput(shootDate)
          },
          faceImg
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
    uploadList.push(uploadFace);
  } 

  return uploadList;
};

export const cepUploadProcess = async (
  cepImg,
  faceImg,
  chartId,
  shootDate
) => {
  const uploadList = cepFileUpload(
    chartId,
    cepImg,
    faceImg,
    shootDate
  );
  return uploadList;
};

const convertURLtoFile = async (url) => {
  const response = await fetch(url);
  const data = await response.blob();
  const ext = url.split('.').pop(); // url 구조에 맞게 수정할 것
  const filename = url.split('/').pop(); // url 구조에 맞게 수정할 것
  const metadata = { type: `image/${ext}` };

  return new File([data], filename, metadata);
};

export const convertingImages = (imgData) => {
  const converting = [];
  const cephFile = convertURLtoFile(imgData.cepparo);
  converting.push(cephFile);

  if (imgData.face) {
    const faceFile = convertURLtoFile(imgData.face);
    converting.push(faceFile);
  }

  return converting;
};

export function getTextWidth(text, fontSize, fontFace) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `${fontSize}px ${fontFace}`;
  return context.measureText(text).width;
}

export const cephLandmarkFunc = async (chartId) => {
  const temp = [];
  try {
    const response = await viewerAPI.cephLandmark({ chart_id: chartId });
    response.output.boxs.map((value) => {
      temp.push({
        Number: value.cname,
        Name: value.name,
        x: value.bbox.x,
        y: value.bbox.y
      });
    });
    return [temp, response];
  } catch (error) {
    throw Error(error);
  }
};

export function chartDisplayCal(el, gender) {
  let mean = 0;
  let sd = 0;

  if (gender === false) {
    mean = el.mean.M;
    sd = el.sd.M;
  } else {
    mean = el.mean.F;
    sd = el.sd.F;
  }

  if (mean === 'null') return 50;

  const z = (el.value - mean) / sd;

  if (z < -6.5) return 0.0;
  if (z > 6.5) return 1.0;

  let factK = 1;
  let sum = 0;
  let term = 1;
  let k = 0;
  const loopStop = Math.exp(-23);
  while (Math.abs(term) > loopStop) {
    term = (((0.3989422804 * (-1) ** k * z ** k) / (2 * k + 1) / 2 ** k)
        * z ** (k + 1))
      / factK;
    sum += term;
    k++;
    factK *= k;
  }
  sum += 0.5;

  return sum * 100;
}

export function getToothTransAttr(box, p1, p2, is6) {
  const txy = {
    x: (p2.x + p1.x) / 2 - box.w / 2,
    y: (p2.y + p1.y) / 2 - box.h / 2
  };
  const length = getLength(p1, p2);
  const scalef = length / box.h;
  const angDeg = getAngle(p1, p2) - 90;

  if (is6) {
    txy.x -= length * 0.1;
    txy.y -= Math.abs(p1.x - p2.x) * 0.01;
  }
  return `translate(${txy.x}px, ${txy.y}px) scale(${scalef}) rotate(${angDeg}deg)`;
}